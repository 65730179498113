export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';
export const INHERITED = 'inherited';

export const cloudProviderNames = {
    'aws': 'AWS',
    'azure': 'Azure',
    'gcp': 'GCP',
}

const cloud_providers = [
    {name: AWS, documentationUrl: "http://www.storagelink.co/redirects/getting-started/aws"},
    {name: AZURE, documentationUrl: "http://www.storagelink.co/redirects/getting-started/azure"},
    {name: GCP, documentationUrl: "http://www.storagelink.co/redirects/getting-started/google"}
];

export default cloud_providers;

export function getServiceProvider(provider) {
    let service;
    switch (provider) {
        case AZURE:
            service = 'Azure Cloud Service';
            break;
        case GCP:
            service = 'Google Cloud Service';
            break;
        case AWS:
            service = 'Amazon Web Service';
            break;
        default:
            service = provider;
            break;
    }
    return service;
}