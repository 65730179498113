import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import 'core-js/features/string/replace-all'
import 'core-js/features/string/includes'
import 'core-js/features/array/find-index'

import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {configureStore} from '@reduxjs/toolkit';
import {Provider} from 'react-redux';
import rootReducer from "./reducer";
import {createRoot} from 'react-dom/client';

const store = configureStore({reducer: rootReducer});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <React.StrictMode>
        <Provider store={store}>
                <App/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
