import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import {
    Box,
    IconButton, List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ContentCopyRounded} from '@mui/icons-material';
import CustomAlert from '../../common/CustomAlert';
import {drawerColor} from '../../layout/ResponsiveDrawer';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function IdentityProviderConfigInfo(props) {

    const [registrationId, setRegistrationId] = useState(props.registrationId);
    const theme = useTheme();

    useEffect(() => {
        setRegistrationId(props.registrationId);
    }, [props.registrationId]);

    const regPrefix = BASE_URL.startsWith("http") ? "" : window.location.origin;
    const values = [
        {
            label: 'Authorized JavaScript Origin',
            value: window.location.origin
        },
        {
            label: 'Authorized Redirect URI / Callback URL',
            value: (registrationId && `${regPrefix}${BASE_URL}/login/oauth2/code/${registrationId}`) ||
                <i>Provide registration id to view Redirect URI</i>
        },
        {
            label: 'Front-channel Logout URI / Sign out URL',
            value: window.location.origin
        },
    ]

    const copyValue = (event, label, value) => {
        event.stopPropagation()
        if (typeof value === "string") {
            navigator.clipboard.writeText(value).then(() => {
                toast.notify(({onClose}) => <CustomAlert type='success' message={`${label} copied!`}
                                                         onClose={onClose}/>);
            });
        } else if (label === "Authorized Redirect URI / Callback URL") {
            toast.notify(({onClose}) => <CustomAlert type='warning' message={'Provide registration id to copy URI'}
                                                     onClose={onClose}/>);
        }
    };

    return <Box pt={2}>
        <Box sx={{backgroundColor: drawerColor, width: 'fit-content'}} p={2}>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant={'h6'}>Fields for Identity Provider</Typography>
                <Typography variant={'caption'} style={{ color: theme.palette.text.secondary}}>
                    Provide these fields when appropriate during configuration of OIDC on the identity provider.
                </Typography>
            </Box>

            <List>
                {values.map(row =>
                    <ListItem key={row.label}>
                        <ListItemText primary={row.label}
                                      secondary={<Box component='span' display='flex' alignItems='center'
                                                      sx={{ color: theme.palette.text.primary}}>
                                          {row.value}
                                          <IconButton size='small'
                                                      onClick={event => copyValue(event, row.label, row.value)}>
                                              <ContentCopyRounded fontSize='small' color='primary'/>
                                          </IconButton></Box>}
                        />
                    </ListItem>
                )}
            </List>
        </Box>
    </Box>;
}