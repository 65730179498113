import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import MuiGrid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {Hidden} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Refresh} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import routes from '../../util/routes';
import {Role} from '../auth/accessControl/role';
import AccessControl from '../auth/accessControl/AccessControl';
import CircularProgressButton from '../common/CircularProgressButton';

const Grid = styled(MuiGrid)(({theme}) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

function ActionButtonsComponent(props) {

    const hiddenFileInput = React.useRef(null);
    const hiddenFolderInput = React.useRef(null);
    const history = useHistory();

    const upload = (event) => {
        props.upload(event);
        event.target.value = null; //reset file selection box
    };

    const handleUploadFileClick = () => {
        hiddenFileInput.current.click();
    };

    const handleUploadFolderClick = () => {
        hiddenFolderInput.current.click();
    };

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={6} sm="auto" mb={1}>
                <CircularProgressButton label={'File Upload'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        onClick={handleUploadFileClick}
                                        disabled={!props.availableActions.upload}
                                        startIcon={<FileUploadOutlinedIcon/>}
                />
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={upload}
                    multiple='multiple'
                    hidden
                />
            </Grid>

            <Grid item xs={6} sm="auto" mb={1}>
                <CircularProgressButton label={'Folder Upload'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        onClick={handleUploadFolderClick}
                                        disabled={!props.availableActions.upload}
                                        startIcon={<DriveFolderUploadOutlinedIcon/>}
                />
                <input
                    onChange={upload}
                    hidden
                    directory=""
                    webkitdirectory=""
                    type="file"
                    ref={hiddenFolderInput}
                />
            </Grid>

            <Grid item xs={4} sm="auto" mb={1}>
                <CircularProgressButton label={'Create'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        disabled={!props.availableActions.createFolder}
                                        onClick={() => history.push(`${routes.filesystemfuncCreateFolder.path(props.path.join('/'))}`)}
                                        startIcon={<AddIcon/>}
                />
            </Grid>

            <AccessControl allowedRoles={[Role.ADMIN]}>
                <Grid item xs={4} sm="auto" mb={1}>
                    <CircularProgressButton label={'Edit'}
                                            fullWidth={false}
                                            buttonTextTransform={'none'}
                                            size={'small'}
                                            style={{borderRadius: '20px'}}
                                            mr={1}
                                            disabled={!props.availableActions.editFolder}
                                            startIcon={<EditOutlinedIcon/>}
                                            onClick={props.edit}
                    />
                </Grid>
            </AccessControl>

            <Hidden smDown>
                <Divider orientation='vertical' variant='middle' flexItem sx={{mr: 1}}/>
            </Hidden>

            <Grid item xs={4} sm="auto" mb={1}>
                <CircularProgressButton label={'Open'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        disabled={!props.availableActions.openFolder}
                                        startIcon={<FolderOpenOutlinedIcon/>}
                                        onClick={props.open}
                />
            </Grid>

            <Grid item xs={4} sm="auto" mb={1}>
                <CircularProgressButton label={'Download'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        disabled={!props.availableActions.download}
                                        startIcon={<FileDownloadOutlinedIcon/>}
                                        onClick={props.download}
                />
            </Grid>

            <Grid item xs={4} sm="auto" mb={1}>
                <CircularProgressButton label={'Delete'}
                                        fullWidth={false}
                                        buttonTextTransform={'none'}
                                        size={'small'}
                                        style={{borderRadius: '20px'}}
                                        mr={1}
                                        disabled={!props.availableActions.delete}
                                        startIcon={<DeleteOutlinedIcon/>}
                                        onClick={props.delete}
                />
            </Grid>

            <Grid item xs={4} sm="auto" mb={1}>
                <CircularProgressButton
                    label={'Refresh'}
                    fullWidth={false}
                    buttonTextTransform={'none'}
                    size={'small'}
                    style={{borderRadius: '20px'}}
                    mr={1}
                    inProgress={props.isRefreshing}
                    startIcon={<Refresh/>}
                    onClick={props.refresh}
                />
            </Grid>

        </Grid>
    );

}

ActionButtonsComponent.propTypes = {
    upload: PropTypes.func,
    create: PropTypes.func,
    download: PropTypes.func,
    delete: PropTypes.func,
    open: PropTypes.func,
    edit: PropTypes.func,
    refresh: PropTypes.func,
    availableActions: PropTypes.object,
    isRefreshing: PropTypes.bool
};

ActionButtonsComponent.defaultProps = {};

export default ActionButtonsComponent;
