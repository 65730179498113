const components = {
  MuiInput: {
    variants: [
      {
        props: {variant: 'system'},
        style: {
          whiteSpace: 'pre-wrap',
          fontFamily: 'monospace-ui, monospace',
          fontSize: '0.875em'
        }
      }
    ]
  }
}

export default components;