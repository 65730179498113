import { isEmpty } from "../../util/helpers";
import httpStatus from "../../util/http_status";
import toast from "toasted-notes";
import CustomAlert from "../../component/common/CustomAlert";
import React from "react";

function handleLoginError(error) {
  if (!isEmpty(error.response)
    && (error.response.status === httpStatus.badRequest || error.response.status === httpStatus.unAuthorized)) {
    toast.notify(({ onClose }) => <CustomAlert type='error'
                                               message={error.response.data.errorMessage || error.response.data}
                                               onClose={onClose}/>);
  } else {
    toast.notify(({ onClose }) =>
      <CustomAlert type='error' message='Something went wrong! Check that the server is running.'
                   onClose={onClose}/>)
  }
}


export default handleLoginError;