import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEmpty } from '../../util/helpers';
import { grey, red } from '@mui/material/colors';


export default function CustomDeleteIcon(props) {
    let color = red['800'];
    if (!isEmpty(props.disabled) && props.disabled) {
        color = grey['500'];
    }
    return (
        <DeleteIcon style={{ color }} {...props}/>
    );
}
