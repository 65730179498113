import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useFilesystem from '../../api/useFilesystem';
import FilesystemListComponent from '../../component/filesystem/FilesystemListComponent';


function FilesystemListContainer() {

    const params = useParams();

    const {
        isLoading,
        isValidating,
        refresh,
        data,
        folderNotFound,
        error,
        numPages,
        setNumPages,
        hasMore
    } =  useFilesystem({
        params: { path: params[0]}}
    );

    const triggerRefresh = useCallback(async function refreshFilesystem() {
        return refresh();
    }, [refresh]);

    const filesystem = data?.reduce((a, b) => a.concat(b.content), []) || [];

    return <FilesystemListComponent loadingFilesystem={isLoading}
                                    refreshingFilesystem={isValidating}
                                    filesystem={filesystem}
                                    notFound={folderNotFound}
                                    triggerRefresh={triggerRefresh}
                                    errorMessage={error?.message}
                                    errorPath={error?.path}
                                    numPages={numPages}
                                    setNumPages={setNumPages}
                                    hasMorePages={hasMore}
    />;
}

export default FilesystemListContainer;
