import { createTheme } from '@mui/material/styles';
import fullImage from './assets/storagelink-full-logo.png';
import navbarBrandImage from './assets/storagelink-full-logo.png';
import manifest from './assets/site.webmanifest';
import favicon32x32 from './assets/favicon-32x32.png';

export default function createMyTheme(options) {

    let {clientBranding: customClientBranding, ...otherCustomOptions} = options;

    const branding = {
        logo: {
            fullImage,
            alt: "StorageLink",
            navbarBrandImage,
        },
        pageTitle: "StorageLink",
        manifest,
        favIcons: [
            {rel: "icon", type: "image/png", sizes: "32x32", href: favicon32x32},
        ]
    }

    // noinspection DuplicatedCode
    return createTheme({
        clientBranding: {
            ...branding,
            ...customClientBranding
        },
        ...otherCustomOptions,
    })
}
