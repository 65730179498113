//Session Profile stores state in memory, which is good for hiding the top bar when embedding
const SessionProfile = (function(){
  let showTopBar = true;

  function setShowTopBar(doShow){
    showTopBar = doShow;
  }

  function getShowTopBar(){
    return showTopBar;
  }

  let defaultStorageDialogDismissed = false;
  function setDefaultStorageDialogDismissed(dismissed){
    defaultStorageDialogDismissed = dismissed;
  }

  function getDefaultStorageDialogDismissed(){
    return defaultStorageDialogDismissed;
  }

  return {
    setShowTopBar,
    getShowTopBar,
    setDefaultStorageDialogDismissed,
    getDefaultStorageDialogDismissed
  }
})();

export default SessionProfile;