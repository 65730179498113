import React from 'react';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { isEmpty } from "../../util/helpers";


function CustomAlert({message, type, onClose, ...props}) {

    return (
        <MuiAlert elevation={0} icon={false} onClose={onClose} severity={type}>
            {isEmpty(message) ? props.children : message}
        </MuiAlert>
    );
}

CustomAlert.propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    onClose: PropTypes.func,
};

CustomAlert.defaultProps = {
    type: 'success',
};

export default CustomAlert;
