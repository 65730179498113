export const Role = {
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_SWIFT'
}

export const RoleDisplayDict = {
    ROLE_ADMIN: 'Admin User',
    ROLE_SWIFT: 'Web User',
}

export const UserType = {
    ROLE_ADMIN: 'ADMIN',
    ROLE_SWIFT: 'USER',
}
