import {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initIdentityProviders = [];

const initDependencies = {
    triggerRefresh: false,
};

function useIdentityProviders({dependencies = initDependencies} = {}) {

    const {triggerRefresh} = dependencies;
    const [identityProviders, setIdentityProviders] = useState(initIdentityProviders);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadIdentityProviders() {
        const fetchIdentityProvidersData = async function fetchIdentityProvidersData() {
            try {
                const result = await axios.get(`${api_routes.identityProvider.endpoint}`);
                setIdentityProviders(result.data.identityProviders);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchIdentityProvidersData();
    }, [triggerRefresh]);

    return {isLoading, identityProviders};
}

export default useIdentityProviders;
