import React, {useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import styled from 'styled-components';
import {Link as RouterLink, NavLink, useHistory} from 'react-router-dom';
import {Icon} from '@iconify/react';
import awsIcon from '@iconify-icons/mdi/aws';
import azureIcon from '@iconify-icons/mdi/microsoft-azure';
import googleIcon from '@iconify-icons/mdi/google-cloud';
import {
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useTheme} from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import PageLoader from '../../common/PageLoader';
import PageSection from '../../common/PageSection';
import CustomAlert from '../../common/CustomAlert';
import ConfirmDialog from '../../common/ConfirmDialog';
import CustomDeleteIcon from '../../common/CustomDeleteIcon';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {
    StyledEditIconButton,
    StyledTableCell,
    StyledTableHead,
    StyledTableHeadCell,
    StyledTableRow
} from '../../common/styled';
import routes from '../../../util/routes';
import api_routes from '../../../util/api_routes';
import {isEmpty} from '../../../util/helpers';
import Feature from '../../common/Feature';
import {getServiceProvider} from '../../../util/cloud_providers';
import useCloudCredentials from "../../../api/useCloudCredentials";
import {CloudProviderIcon} from "../../common/ProviderIcon";

const StyledCredentialButton = styled(Button)`
  text-transform: none;
`;

export const StyledRouterLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

function SettingsCompCloudCredentialsList() {

    const theme = useTheme();

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const {
        credentials: credientialsList,
        isLoading,
        refresh
    } = useCloudCredentials();

    const [showConfirmDeleteId, setShowConfirmDeleteId] = useState(null);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    async function deleteCloudCredential(cloudCredential) {
        try {
            await axios.delete(`${api_routes.cloudCredentials.endpoint}/${cloudCredential.id}`);
            await refresh()
            setShowSavedMessage(true);
        } catch (error) {
            if (!isEmpty(error.response)) {
                toast.notify(({onClose}) =>
                    <CustomAlert message={error.response.data.message}
                                 onClose={onClose} type='error'/>);
            }
        }
    }

    function goToCredential(credential) {
        return () =>
            history.push(
                `${routes.settings.path}${routes.cloudCredentials.path}/${credential.id}${routes.updateCloudCredentials.path}`
            );
    }

    return (
        <>
            <PageSection title='Cloud Credentials'
                         subtitle='The following credentials are used to authorize a folders to access a cloud storage location.'
                         action={<Grid container alignItems='center'>
                             <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage}
                                                      mr={2}/>
                             <StyledCredentialButton theme={theme} size='small' color='primary'
                                                     variant='contained'
                                                     disableElevation
                                                     aria-controls='user-menu'
                                                     aria-haspopup='true'
                                                     onClick={(event) => setAnchorEl(event.currentTarget)}
                                                     endIcon={<ArrowDropDownIcon/>}>
                                 Add New Cloud Credential
                             </StyledCredentialButton>
                             <Menu id='user-menu'
                                   keepMounted
                                   anchorEl={anchorEl}
                                   open={Boolean(anchorEl)}
                                   onClose={() => setAnchorEl(null)}
                                   anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right'
                                   }}
                                   transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right'
                                   }}
                             >
                                 <StyledRouterLink theme={theme}
                                                   to={`${routes.settings.path}${routes.cloudCredentials.path}${routes.createCloudCredentials.path}?provider=aws`}>
                                     <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                         <StyledListItemIcon><Icon width={22}
                                                                   icon={awsIcon}/></StyledListItemIcon>
                                         Amazon Web Services
                                     </StyledMenuItem>
                                 </StyledRouterLink>
                                 <Feature name='azure'>
                                     <StyledRouterLink theme={theme}
                                                       to={`${routes.settings.path}${routes.cloudCredentials.path}${routes.createCloudCredentials.path}?provider=azure`}>
                                         <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                             <StyledListItemIcon><Icon width={22}
                                                                       icon={azureIcon}/></StyledListItemIcon>
                                             Azure Cloud Services
                                         </StyledMenuItem>
                                     </StyledRouterLink>
                                 </Feature>
                                 <Feature name='gcp'>
                                     <StyledRouterLink theme={theme}
                                                       to={`${routes.settings.path}${routes.cloudCredentials.path}${routes.createCloudCredentials.path}?provider=gcp`}>
                                         <StyledMenuItem onClick={() => setAnchorEl(null)}>
                                             <StyledListItemIcon><Icon width={22}
                                                                       icon={googleIcon}/></StyledListItemIcon>
                                             Google Cloud Services
                                         </StyledMenuItem>
                                     </StyledRouterLink>
                                 </Feature>
                             </Menu>
                         </Grid>}
            />
            <TableContainer>
                <Table aria-label='cloud-credentials-table'>
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '65%'}}/>
                        <col style={{width: '15%'}}/>
                    </colgroup>
                    <StyledTableHead theme={theme}>
                        <TableRow>
                            <StyledTableHeadCell theme={theme} align='left'>
                                Cloud Provider
                            </StyledTableHeadCell>
                            <StyledTableHeadCell theme={theme} align='left'>
                                Display Name
                            </StyledTableHeadCell>
                            <StyledTableHeadCell theme={theme} align='center'>
                                Actions
                            </StyledTableHeadCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>

                        {/* PAGE LOADER */}
                        {isLoading &&
                            <StyledTableRow theme={theme}>
                                <StyledTableCell colSpan={3}><PageLoader/></StyledTableCell>
                            </StyledTableRow>
                        }

                        {/* NO CONTENT */}
                        {!isLoading && credientialsList?.length === 0 &&
                            <StyledTableRow theme={theme}>
                                <StyledTableCell align='center' colSpan={3}>
                                    No records to display
                                </StyledTableCell>
                            </StyledTableRow>
                        }

                        {/* CONTENT */}
                        {!isLoading && credientialsList?.map((credential, idx) => (
                            <StyledTableRow theme={theme} key={JSON.stringify(credential || idx)}>

                                {/* PROVIDER */}
                                <StyledTableCell onClick={goToCredential(credential)}>
                                    <StyledTableIcon>
                                        <CloudProviderIcon provider={credential.cloudProvider}/>

                                        <Typography ml={2}>{getServiceProvider(
                                            credential.cloudProvider)}</Typography>
                                    </StyledTableIcon>
                                </StyledTableCell>

                                {/* DISPLAY NAME */}
                                <StyledTableCell onClick={goToCredential(credential)}
                                                 align='left'>
                                    <Typography>{credential.displayName}</Typography>
                                </StyledTableCell>

                                {/* ACTION CELL */}
                                <StyledTableCell align='center'>
                                    <RouterLink
                                        to={`${routes.settings.path}${routes.cloudCredentials.path}/${credential.id}${routes.updateCloudCredentials.path}`}>
                                        <StyledEditIconButton size='small'>
                                            <EditIcon fontSize='small' color='primary'/>
                                        </StyledEditIconButton>
                                    </RouterLink>
                                    <IconButton size='small'
                                                onClick={() => setShowConfirmDeleteId(credential.id)}>
                                        <CustomDeleteIcon fontSize='small'/>
                                    </IconButton>
                                    <ConfirmDialog
                                        title='Delete Cloud Credential'
                                        open={showConfirmDeleteId === credential.id}
                                        setOpen={setShowConfirmDeleteId}
                                        onConfirm={() => deleteCloudCredential(credential)}
                                    >
                                        Are you sure you want to delete {credential.displayName}?
                                    </ConfirmDialog>
                                </StyledTableCell>
                            </StyledTableRow>

                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

SettingsCompCloudCredentialsList.propTypes = {};

SettingsCompCloudCredentialsList.defaultProps = {};

export default SettingsCompCloudCredentialsList;
