import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Box} from '@mui/material';
import {useTheme} from '@mui/styles';
import FolderForm from './FolderForm';
import CircularProgressButton from '../common/CircularProgressButton';
import {Prompt} from "react-router";

function FolderFormWrapper(props) {

    const folderFormRef = useRef();

    const theme = useTheme();
    const history = useHistory();

    const [submitting, setSubmitting] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            setSubmitting(true);
            await folderFormRef.current.handleSubmit(event);
        }
        catch (error){
            setSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <FolderForm ref={folderFormRef} setIsFolderFormBlocking={setIsBlocking} {...props}/>
            <Prompt when={!submitting && isBlocking}
                    message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>

            <Box display='flex'>
                <CircularProgressButton theme={theme} type='submit' size='small' label='Save' mt={2} mr={1}
                                        inProgress={submitting}/>
                <CircularProgressButton theme={theme} variant='text' size='small' label='Cancel'
                                        onClick={() => history.goBack()} mt={2}/>
            </Box>
        </form>
    );
}

FolderFormWrapper.propTypes = {}

FolderFormWrapper.defaultProps = {};

export default FolderFormWrapper;
