import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import routes from '../../util/routes';

export const StyledBreadcrumbLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`;

function FilesystemBreadcrumbs(props) {

    const history = useHistory();
    const theme = useTheme();
    const [maxBreadcrumbWidth, setMaxBreadcrumbWidth] = useState(theme.breakpoints.values.sm);

    const isExtraSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isExtraLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('xl'));

    useEffect(() => {
        if (isExtraSmallScreen) {
            setMaxBreadcrumbWidth(theme.breakpoints.values.sm / 2 - 25);
        } else if (isSmallScreen && !isMediumScreen) {
            setMaxBreadcrumbWidth(theme.breakpoints.values.md / 2 + 50);
        } else if (isMediumScreen && !isLargeScreen) {
            setMaxBreadcrumbWidth(theme.breakpoints.values.md - 300)
        } else if (isLargeScreen && !isExtraLargeScreen) {
            setMaxBreadcrumbWidth(theme.breakpoints.values.lg - 350);
        } else if (isExtraLargeScreen) {
            setMaxBreadcrumbWidth('100%');
        }
    }, [isExtraSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen,
        theme.breakpoints.values.sm, theme.breakpoints.values.md, theme.breakpoints.values.lg]);

    const getMaxItemWidth = () => {
        return maxBreadcrumbWidth;
    };

    const breadcrumbItemStyle = {
        maxWidth: getMaxItemWidth(),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return <Breadcrumbs sx={{mt: 1, mb: 1}} maxItems={4}
        separator={<NavigateNextIcon fontSize='small'/>}
        aria-label='filesystem-breadcrumb'
    >
        <StyledBreadcrumbLink underline='hover' key='-1' color='inherit'
              onClick={() => history.push(routes.filesystemfunc.path(''))}>
            Home
        </StyledBreadcrumbLink>
        {props.path?.map((name, index) => {
            if (index < props.path.length - 1) {
                return <Typography key={index} variant='body1' component='div' style={breadcrumbItemStyle}>
                    <StyledBreadcrumbLink underline='hover' color='inherit'
                             onClick={() => history.push(routes.filesystemfunc.path(props.path?.slice(0, index + 1)?.join('/')))}>
                    {name}
                </StyledBreadcrumbLink>
                </Typography>
            } else {
                return <Typography key={index} color='text.primary' style={breadcrumbItemStyle}>{name}</Typography>
            }
        })}
    </Breadcrumbs>
}

FilesystemBreadcrumbs.propTypes = {
};

FilesystemBreadcrumbs.defaultProps = {
};

export default FilesystemBreadcrumbs;
