import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FormControlInput from '../../common/FormControlInput';

function GoogleCloudConnectionForm(props) {

    const {onCloudConnectionChange} = props;

    const [cloudConnection, setCloudConnection] = useState({
        basePrefix: props.basePrefix
    });

    const handleTextChange = (event) => {
        setCloudConnection((prevState) => ({...prevState, [event.target.name]: event.target.value}));
    }

    useEffect(() => {
        if(onCloudConnectionChange){
            onCloudConnectionChange(cloudConnection);
        }
    }, [cloudConnection, onCloudConnectionChange]);

    return (
      <>
          <FormControlInput value={cloudConnection.basePrefix} width='100' label='GCS Bucket URI or Bucket Name' name='basePrefix'
                            autoComplete="off"
                            onChange={handleTextChange} placeholder='Enter GsUtil URI or Bucket Name for GCP Bucket'
                            helperText='Ex: gs://bucket-name'
                            errorMessage={props.errors?.basePrefix}
                            allowCopy
          />
    </>
    );
}

GoogleCloudConnectionForm.propTypes = {
    basePrefix: PropTypes.string,
    errors: PropTypes.object,
    onCloudConnectionChange: PropTypes.func,
};
GoogleCloudConnectionForm.defaultProps = {
    errors: {}
};
export default GoogleCloudConnectionForm;
