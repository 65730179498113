import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CircularProgressButton from './common/CircularProgressButton';
import { isEmptyObject } from '../util/helpers';
import routes from '../util/routes';
import SessionProfile from '../util/SessionProfile';


function SystemInfoComponent() {
    const history = useHistory();
    const [openSystemInfoDialog, setOpenSystemInfoDialog] = useState(false);
    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    useEffect(() => {
        // noinspection ChainedFunctionCallJS
        let systemHasErrors = Object.keys(systemInfo).some(key => !systemInfo[key]);
        setOpenSystemInfoDialog(systemHasErrors);

        if (!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet && !SessionProfile.getDefaultStorageDialogDismissed()) {
            // Automatically redirect to the Update root folder Form
            history.push(
                `${routes.filesystemfuncUpdateFolder.path("")}&provider=${systemInfo.cloudProvider}&redirect=${routes.homeAdmin.path}&help=true`);
        }
    }, [systemInfo, history]);

    const onDefaultCloudConnectionDialogDismissed = useCallback(function handleClose() {
        setOpenSystemInfoDialog(false);
        SessionProfile.setDefaultStorageDialogDismissed(true);
    }, [setOpenSystemInfoDialog]);

    return (
        <>
            {!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet && !SessionProfile.getDefaultStorageDialogDismissed() &&
            <Dialog open={openSystemInfoDialog} onClose={onDefaultCloudConnectionDialogDismissed}>

                <DialogTitle>Configure Default Storage</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        StorageLink requires a connection to cloud storage for users to store and retrieve files.
                        Use the underlying form to map the root folder to a cloud storage location.
                    </DialogContentText>
                    <CircularProgressButton onClick={onDefaultCloudConnectionDialogDismissed} buttonTextTransform='none'
                                            label='OK'/>
                </DialogContent>

            </Dialog>
            }
        </>
    );
}

SystemInfoComponent.propTypes = {};

SystemInfoComponent.defaultProps = {};

export default SystemInfoComponent;
