import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import CircularProgressButton from '../common/CircularProgressButton';
import { convertErrorsToObject } from '../../util/errorHandler';
import httpStatus from '../../util/http_status';
import api_routes from '../../util/api_routes';
import PasswordField from '../common/PasswordField';
import { useTheme } from '@mui/material/styles';
import { StyledFormContent, StyledFormLogo, StyledFormWrapper, StyledLogo } from './Login';

const initChangePasswordForm = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
}


function UpdatePassword(props) {

    const { currentPassword } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState(initChangePasswordForm);
    const [changePasswordForm, setChangePasswordForm] = useState(initChangePasswordForm);
    const theme = useTheme();

    const handlePasswordUpdate = async function handlePasswordUpdateOnThePasswordUpdateForm(event) {
        event.preventDefault();
        setIsLoading(true);
        if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
            try {
                await axios.patch(`${api_routes.user.endpoint}/password`, {
                    currentPassword: currentPassword || changePasswordForm.currentPassword,
                    newPassword: changePasswordForm.newPassword
                });
                props.onSuccess(props.username, changePasswordForm.newPassword);
            } catch (error) {
                if (error.response.status === httpStatus.badRequest) {
                    let tempErrors = convertErrorsToObject(error.response);
                    setPasswordErrors({ confirmNewPassword: '', newPassword: tempErrors.newPassword });
                    setIsLoading(false);
                }
            }
        } else {
            setPasswordErrors({ newPassword: '', confirmNewPassword: 'New passwords don\'t match' });
            setIsLoading(false);
        }
    };

    const handleChange = function updateChangePasswordFormFields(event) {
        setChangePasswordForm(
            { ...changePasswordForm, [event.target.name]: event.target.value })
    };

    return (
        <Grid container justifyContent='center' alignItems='center' className='login-form'>
            <StyledFormWrapper>
                <StyledFormLogo>
                    <StyledLogo src={theme.clientBranding.logo.navbarBrandImage}
                                alt={theme.clientBranding.logo.alt}/>
                </StyledFormLogo>
                <StyledFormContent className='form-content'>
                    Update Password
                </StyledFormContent>
                <Box sx={{ width: '100%' }}>
                    <form onSubmit={handlePasswordUpdate}>
                        {!currentPassword &&
                            <PasswordField width='100' name='currentPassword'
                                           placeholder='Enter current password'
                                           label='Current Password'
                                           password={changePasswordForm.currentPassword}
                                           onChange={handleChange}
                                           errorMessage={passwordErrors.currentPassword}/>
                        }
                        <PasswordField width='100' name='newPassword'
                                       placeholder='Enter complex password' label='New Password'
                                       tooltip="At least one capital letter, one lower case letter, one number,
                                          and one special character"
                                       password={changePasswordForm.newPassword} onChange={handleChange}
                                       errorMessage={passwordErrors.newPassword}/>
                        <PasswordField errorMessage={passwordErrors.confirmNewPassword}
                                       width='100' placeholder='Re-enter complex password'
                                       name='confirmNewPassword' password={changePasswordForm.confirmNewPassword}
                                       label='Confirm New Password' onChange={handleChange}/>
                        <CircularProgressButton label='Update Password' variant='contained' mt={2}
                                                type='submit' inProgress={isLoading}/>
                    </form>
                </Box>
            </StyledFormWrapper>
        </Grid>
    );
}

UpdatePassword.propTypes = {
    currentPassword: PropTypes.string.isRequired,
    username: PropTypes.string,
    onSuccess: PropTypes.func,
};

UpdatePassword.defaultProps = {};

export default UpdatePassword;
