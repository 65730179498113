import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Role } from './role';
import { hasAccess } from './AccessControl';
import routes from '../../../util/routes';
import { isUndefined } from '../../../util/helpers';


function PrivateRoute({ children, allowedRoles, ...rest }) {

    const location = useLocation();

    const currentUser = useSelector(function getCurrentUserForPrivateRouteCheck(state) {
        return state.currentUser
    });

    if (hasAccess(currentUser.roles, allowedRoles)) {
        return <Route {...rest}>
            {children}
        </Route>;
    }

    if (!isUndefined(currentUser.roles) && (currentUser.roles).includes(Role.ADMIN)) {
        if (location.pathname.startsWith(routes.homeAdmin.path)) {
            return <Redirect to={location.pathname} />
        }
        return <Redirect to={routes.homeAdmin.path} />
    }

    if (!isUndefined(currentUser.roles) && (currentUser.roles).includes(Role.USER)) {
        if (location.pathname.startsWith(routes.homeSwift.path)) {
            return <Redirect to={location.pathname} />
        }
        return <Redirect to={routes.homeSwift.path} />
    }

    return null;
}

PrivateRoute.propTypes = {
    allowedRoles: PropTypes.array.isRequired,
};

PrivateRoute.defaultProps = {
    allowedRoles: [],
};

export default PrivateRoute;
