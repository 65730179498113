import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from '../../util/helpers';

const StyledWrapper = styled.div`
    padding-bottom: ${props => props.theme.spacing(props.pb)};
    display: flex;
    align-items: center;
    margin-top: ${props => props.theme.spacing(props.mt)};
    width: 100%;
`;

const StyledLeftElement = styled.div`
    flex: 1 1 auto;
    padding-right: ${props => { 
        // If the action section is present, add padding right, else no padding.
        let spacing = 0;
        if (props.includepaddingright) { 
            spacing = props.theme.spacing(2)
        }
        return spacing;
    }}px;
`;

const StyledRightElement = styled.div`
    flex: 0 0 auto;
    padding-left: 1em;
`;


export default function PageSection({title, subtitle, action, mt, pb, titleVariant, subtitleVariant}) {
    const theme = useTheme();

    return (
        <StyledWrapper mt={mt} pb={pb} theme={theme}>
            <StyledLeftElement theme={theme} includepaddingright={!isEmpty(action)}>
                <Typography variant={titleVariant}>{title}</Typography>
                <Typography variant={subtitleVariant} color='textSecondary'>{subtitle}</Typography>
            </StyledLeftElement>
            <StyledRightElement>{action}</StyledRightElement>
        </StyledWrapper>
    );
}

PageSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    action: PropTypes.node,
    mt: PropTypes.number, // margin-top
    titleVariant: PropTypes.string,
    subtitleVariant: PropTypes.string,
    pb: PropTypes.number, // padding-bottom
};

PageSection.defaultProps = {
    mt: 0,
    titleVariant: 'h6',
    subtitleVariant: 'body1',
    pb: 2,
};
