import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import CircularProgressButton from '../common/CircularProgressButton';
import FormControlInput, {
    StyledFormControl,
    StyledFormHelperText,
    StyledTypographyLabel
} from '../common/FormControlInput';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import { convertErrorsToObject } from '../../util/errorHandler';
import { isEmpty } from '../../util/helpers';
import { StyledNavLogo } from '../layout/ResponsiveDrawer';

export default function ThemeCustomizationForm() {

    const theme = useTheme();

    const hiddenFileInput = React.useRef(null);
    const hiddenIconInput = React.useRef(null);

    const [primaryThemeColor, setPrimaryThemeColor] = useState(theme.palette.primary.main);
    const [secondaryThemeColor, setSecondaryThemeColor] = useState(theme.palette.secondary.main);
    const [primaryFontColor, setPrimaryFontColor] = useState(theme.palette.text.primary);
    const [secondaryFontColor, setSecondaryFontColor] = useState(theme.palette.text.secondary);
    const [title, setTitle] = useState(theme.clientBranding.pageTitle);
    const [logo, setLogo] = useState(null);
    const [icon, setIcon] = useState(null);

    const [errors, setErrors] = useState({});

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('primaryThemeColor', primaryThemeColor);
        formData.append('secondaryThemeColor', secondaryThemeColor);
        formData.append('primaryFontColor', primaryFontColor);
        formData.append('secondaryFontColor', secondaryFontColor);
        formData.append('title', title);
        formData.append('logo', logo);
        formData.append('favicon', icon);

        try {
            await axios.post(api_routes.theme.endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            window.location.reload(true);
        } catch (error) {
            if (error.response && (error.response.status === httpStatus.badRequest)) {
                let tempErrors = convertErrorsToObject(error.response);
                setErrors(tempErrors);
            }
            console.error('Failed to upload file:', error);
        }
    }

    const handleUploadFileClick = () => {
        hiddenFileInput.current.click();
    };

    const handleUploadIconClick = () => {
        hiddenIconInput.current.click();
    };

    const handleLogoChange = (event) => {
        setLogo(event.target.files[0]);

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };

        if (file && file.type.startsWith('image/')) {
            setErrors({...errors, "logo": undefined});
            reader.readAsDataURL(file);
        } else {
            setErrors({...errors, "logo": "Only images are allowed."});
        }
    };

    const handleIconChange = (event) => {
        setIcon(event.target.files[0]);

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedIcon(reader.result);
        };

        reader.onload = function (e) {
            const image = new Image();
            image.src = e.target.result;

            image.onload = function () {
                const width = image.width;
                const height = image.height;
                if (width !== 32 || height !== 32) {
                    setErrors({...errors, "icon": `Your image size is ${width}x${height}. It must be 32x32.`});
                } else {
                    setErrors({...errors, "icon": undefined});
                }
            };
        };

        if (file && file.type.startsWith('image/')) {
            reader.readAsDataURL(file);
        } else {
            setErrors({...errors, "icon": "Only images are allowed."});
        }
    };

    const resetToDefault = async (event) => {
        event.preventDefault();
        await axios.delete(api_routes.theme.endpoint);
        window.location.reload(true);
    }

    const handleCancel = () => {
        setPrimaryThemeColor(theme.palette.primary.main);
        setSecondaryThemeColor(theme.palette.secondary.main);
        setPrimaryFontColor(theme.palette.text.primary);
        setSecondaryFontColor(theme.palette.text.secondary);
        setTitle(theme.clientBranding.pageTitle);
        setLogo(theme.clientBranding.logo.navbarBrandImage);
        setIcon(theme.clientBranding.favIcons[0].href);

        setErrors({});

        setSelectedImage(null);
        setSelectedIcon(null);
    }

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">

            <Grid container>
                <Grid item xs={12} md={8} lg={6} xl={5}>
                    <Typography variant={'body1'}>Logo</Typography>
                    <Box display={'inline-flex'} alignItems={'center'}>
                        <>{selectedImage ? (
                            <StyledNavLogo src={selectedImage} alt="Selected"/>
                        ) : (
                            <StyledNavLogo src={theme.clientBranding.logo.navbarBrandImage}/>
                        )}</>
                        <>
                            <CircularProgressButton label={'Browse'}
                                                    fullWidth={false}
                                                    buttonTextTransform={'none'}
                                                    size={'small'}
                                                    mt={0}
                                                    ml={2}
                                                    onClick={handleUploadFileClick}
                            />
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleLogoChange}
                                hidden
                            />
                        </>
                    </Box>
                    {errors.logo ? (
                        <StyledFormHelperText sx={{color: theme.palette.error.main}}>{errors.logo}</StyledFormHelperText>
                    ) : (
                        <StyledFormHelperText>Logo appears on the navbar and on the login page. For best results, the height
                            of the image should be around 45px.</StyledFormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} md={8} lg={6} xl={5} sx={{mt: 2}}>
                    <Typography variant={'body1'}>Favicon</Typography>
                    <Box display={'inline-flex'} alignItems={'center'}>
                        <>{selectedIcon ? (
                            <img src={selectedIcon} alt="Selected"/>
                        ) : (
                            <img src={theme.clientBranding.favIcons[0].href} alt={"favicon"}/>
                        )}</>
                        <>
                            <CircularProgressButton label={'Browse'}
                                                    fullWidth={false}
                                                    buttonTextTransform={'none'}
                                                    size={'small'}
                                                    ml={2}
                                                    mt={0}
                                                    onClick={handleUploadIconClick}
                            />
                            <input
                                type="file"
                                ref={hiddenIconInput}
                                onChange={handleIconChange}
                                hidden
                            />
                        </>
                    </Box>
                    {errors.icon ? (
                        <StyledFormHelperText sx={{color: theme.palette.error.main}}>{errors.icon}</StyledFormHelperText>
                    ) : (
                        <StyledFormHelperText>Small image displayed to the left of the page title. The size must be
                            32x32.</StyledFormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} md={8} lg={6} xl={5}>
                    <FormControlInput value={title} width='100' label='Page Title' name='title'
                                      errorMessage={errors.title}
                                      onChange={event => {
                                          setTitle(event.target.value);
                                      }}
                                      helperText={'Page title is shown in the browser\'s title bar.'}/>
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} md={8} lg={6} xl={5}>
                    <Grid container columnSpacing={{sm: 4, md: 6, lg: 8}}>
                        <Grid item xs={12} sm={6}>
                            <ColorField label='Primary Theme Color' value={primaryThemeColor} name='primaryThemeColor'
                                        errorMessage={errors.primaryThemeColor}
                                        onChange={event => {
                                            setPrimaryThemeColor(event.target.value);
                                        }} />

                            <ColorField label='Secondary Theme Color' value={secondaryThemeColor}
                                        name='secondaryThemeColor' errorMessage={errors.secondaryThemeColor}
                                        onChange={event => {
                                            setSecondaryThemeColor(event.target.value);
                                        }}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ColorField label='Primary Font Color' value={primaryFontColor} name='primaryFontColor'
                                        errorMessage={errors.primaryFontColor}
                                        onChange={event => {
                                            setPrimaryFontColor(event.target.value);
                                        }}/>

                            <ColorField label='Secondary Font Color' value={secondaryFontColor}
                                        name='secondaryFontColor' errorMessage={errors.secondaryFontColor}
                                        onChange={event => {
                                            setSecondaryFontColor(event.target.value);
                                        }}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12} md={8} lg={6} xl={5}>
                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <Box display='flex'>
                            <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                                    disabled={!isEmpty(errors.icon) || !isEmpty(errors.logo)}
                                                    label='Save' mt={2} inProgress={false} fullWidth={false}/>
                            <CircularProgressButton label='Cancel' theme={theme} type='reset' size='small' mt={2}
                                                    onClick={handleCancel} variant='text'/>
                        </Box>
                        <Box>
                            <CircularProgressButton label='Reset To Default' theme={theme} size='small' mt={2}
                                                    onClick={resetToDefault} variant='text'/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

}

const StyledColorInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;

  &::-webkit-color-swatch {
    border-radius: 15%;
    border: solid 1px black;
  }

  &::-moz-color-swatch {
    border-radius: 15%;
    border: solid 1px black;
  }
`

function ColorField(props) {
    return (
        <StyledFormControl margin='normal' width='100'>
            <StyledTypographyLabel variant='body1'>{props.label}</StyledTypographyLabel>
            <Box sx={{display: 'inline-flex'}}>
                <StyledColorInput type='color' value={props.value} name={props.name} onChange={props.onChange}/>
                <FormControlInput name={props.name} value={props.value} margin='none' width='100'
                                  onChange={props.onChange} helperText={props.helperText}
                                  errorMessage={props.errorMessage}/>
            </Box>
        </StyledFormControl>
    )
}

ThemeCustomizationForm.propTypes = {}
