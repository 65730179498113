// noinspection JSXDomNesting

import React, { useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import routes from '../../../util/routes';
import api_routes from '../../../util/api_routes';
import useIdentityProviders from '../../../api/useIdentityProviders';
import CircularProgressButton from '../../common/CircularProgressButton';
import PageSection from '../../common/PageSection';
import {
  StyledEditIconButton,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableRow
} from '../../common/styled';
import { StyledChip } from '../../user/formFields/SSHKeyTable';
import CustomDeleteIcon from '../../common/CustomDeleteIcon';
import ConfirmDialog from '../../common/ConfirmDialog';
import PageLoader from '../../common/PageLoader';
import { RoleDisplayDict, UserType } from '../../auth/accessControl/role';


function SettingsCompIdpList() {

    const theme = useTheme();
    const history = useHistory();
    const [deleteId, setDeleteId] = useState(null);
    const [triggerRefresh, setRefresh] = useState(false);
    const {isLoading, identityProviders} = useIdentityProviders({dependencies: {triggerRefresh}});

    const refresh = function refreshListOfIdentityProviders() {
        setRefresh(!triggerRefresh);
    };

    const deleteIdentityProvider = async function deleteIdentityProvider(identityProvider) {
        try {
            await axios.delete(`${api_routes.identityProvider.endpoint}/${identityProvider.id}`);
            refresh();
        } catch (error) {
            console.error(error);
        }
    }

    const goToCreateIdpPage = () => {
        history.push(`${routes.settings.path}${routes.identityProviders.path}${routes.createIdentityProvider.path}`);
    };

    const goToEditIdpPage = (id) => {
        history.push(`${routes.settings.path}${routes.identityProviders.path}/${id}${routes.updateIdentityProvider.path}`);
    };

    return (
            <>
                <PageSection title={'Identity Providers'}
                             subtitle={'Configure external authentication methods to access StorageLink.'}
                             action={
                                 <Grid container alignItems='center'>
                                     <Grid item>
                                         <CircularProgressButton mt={0} size='small'
                                                                 onClick={goToCreateIdpPage}
                                                                 buttonTextTransform='none' label='Add new IdP'
                                         />
                                     </Grid>
                                 </Grid>
                             }
                />
                <TableContainer>
                    <Table aria-label='identity-providers-table'>
                        <colgroup>
                            <col style={{width: '25%'}}/>
                            <col style={{width: '35%'}}/>
                            <col style={{width: '15%'}}/>
                            <col style={{width: '15%'}}/>
                        </colgroup>
                        <StyledTableHead theme={theme}>
                            <TableRow>
                                <StyledTableHeadCell theme={theme} align='left'>Display Name</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='left'>Default User Role</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='left'>Status</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='center'>Actions</StyledTableHeadCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {isLoading &&
                                <StyledTableRow theme={theme}>
                                    <StyledTableCell colSpan={4}><PageLoader/></StyledTableCell>
                                </StyledTableRow>
                            }
                            {!isLoading && identityProviders.length === 0 &&
                                <StyledTableRow theme={theme}>
                                    <StyledTableCell align='center' colSpan={4}>No records to display</StyledTableCell>
                                </StyledTableRow>
                            }
                            {!isLoading && identityProviders.map((idp, idx) => (
                                <StyledTableRow theme={theme} key={idp.id || idx}>
                                    <StyledTableCell onClick={() => goToEditIdpPage(idp.id)}>
                                        {idp.name}
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => goToEditIdpPage(idp.id)}>
                                        {idp.type === UserType.ROLE_ADMIN ? RoleDisplayDict.ROLE_ADMIN : RoleDisplayDict.ROLE_SWIFT}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {idp.enabled ? (
                                            <StyledChip size='small' label='Enabled' style={{backgroundColor: green['100']}}/>
                                        ) : (
                                            <StyledChip size='small' label='Disabled' style={{backgroundColor: red['100']}}/>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <RouterLink to={`${routes.settings.path}${routes.identityProviders.path}/${idp.id}${routes.updateIdentityProvider.path}`}>
                                            <StyledEditIconButton size='small'>
                                                <EditIcon fontSize='small' color='primary' />
                                            </StyledEditIconButton>
                                        </RouterLink>
                                        <IconButton size='small' onClick={() => setDeleteId(idp.id)}>
                                            <CustomDeleteIcon fontSize='small'/>
                                        </IconButton>
                                        <ConfirmDialog
                                            title='Delete Identity Provider'
                                            open={deleteId === idp.id}
                                            setOpen={setDeleteId}
                                            onConfirm={() => deleteIdentityProvider(idp)}
                                        >
                                            Are you sure you want to delete {idp.name}?
                                        </ConfirmDialog>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
    );
}

SettingsCompIdpList.propTypes = {};

SettingsCompIdpList.defaultProps = {};

export default SettingsCompIdpList;
