import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import toast from 'toasted-notes';
import api_routes from '../util/api_routes';
import { handleErrors } from '../util/errorHandler';
import {isEmpty, isUndefined} from '../util/helpers';
import httpStatus from '../util/http_status';
import { authenticationService } from '../container/auth/authenticationService';
import CustomAlert from '../component/common/CustomAlert';
import { setAuthenticated } from '../action';
import { Role } from '../component/auth/accessControl/role';

const initParams = {
    // User id to fetch by
    userId: 0
};

export const initUser = {
    username: '',
    role: Role.USER,
    roles: [],
    notes: '',
    password: '',
    resetPassword: true,
    homeFolderPath: '',
    homeFolderPermissions: {
        downloadable: true,
        uploadable: true,
        deletable: true,
        listable: true,
        inheritable: false
    }
};

function convertRolesToRole(roles) {
    let role = roles.find(role => role === Role.ADMIN);
    if (isUndefined(role)) {
        role = Role.USER;
    }
    return role;
}

function useUser({params = initParams}) {

    const dispatch = useDispatch();

    const {userId} = params;

    const [user, setUser] = useState(initUser);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadUser() {
        const fetchUserData = async function fetchUserData() {
            try {
                // Fetch user by user id
                const userResponse = await axios.get(`${api_routes.user.endpoint}/${userId}`);
                setUser(u=> ({...u, ...userResponse.data, role: convertRolesToRole(userResponse.data.roles)}));
                setIsLoading(false);
            } catch (error) {
                const tempErrors = handleErrors(error.response);
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                // Let the app know that this user is no longer authenticated (this will trigger the login page)
                if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                    authenticationService.clearTokenInfo();
                    dispatch(setAuthenticated(false));
                }
            }
        };
        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchUserData();
    }, [userId, dispatch]);

    const updateUser = async function updateUserApi(user) {
        return axios.put(`${api_routes.user.endpoint}/${userId}`, user).then(response => {
            let data = response.data;
            data = {...data, role: convertRolesToRole(data.roles)};
            return {...response, data};
        });
    }

    const deleteUser = async function deleteUserApi() {
        return await axios.delete(`${api_routes.user.endpoint}/${userId}`);
    }

    return {isLoading, user, updateUser, deleteUser};
}

export default useUser;
