import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, CardHeader, IconButton, LinearProgress, Typography } from '@mui/material';
import { truncateString } from '../../util/helpers';


const progressStyle = {
    position: 'absolute',
    bottom: '-65px',
    right: '24px',
    minWidth: '360px',
    borderBottom: 'none',
    borderBottomRightRadius: 'unset',
    borderBottomLeftRadius: 'unset',
    zIndex: 1,
}

function ProgressCard(props) {


    return (
        <Card className={'progress'} variant='outlined' sx={progressStyle}>
            <CardHeader
                subheader={props.message}
                action={
                    <IconButton aria-label="close" onClick={props.onClose} size='small'>
                        <CloseIcon fontSize='small'/>
                    </IconButton>
                }
            />
            <CardContent sx={{maxHeight: '275px', overflowY: 'auto',
              overscrollBehavior: 'contain'}}>
                {Object.keys(props.progress).map(key =>
                    (
                        <Box key={key} pb={1}>
                            <Typography
                                variant='body2'>{truncateString(props.progress[key].name, 80)}</Typography>
                            <LinearProgress variant="determinate" value={props.progress[key].percentage}/>
                        </Box>
                    )
                )}
            </CardContent>
        </Card>
    );

}

ProgressCard.propTypes = {
    progress: PropTypes.object,
    message: PropTypes.string,
    onClose: PropTypes.func,
};

ProgressCard.defaultProps = {
    progress: {},
    message: '',
    onClose: () => {},
};

export default ProgressCard;
