import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import routes from '../../../util/routes';
import api_routes from '../../../util/api_routes';
import { convertErrorsToObject } from '../../../util/errorHandler';
import IdentityProviderForm from './IdentityProviderForm';
import { IdentityProviderConfigInfo } from './IdentityProviderConfigInfo';
import HelpSystemContext from '../../../context/HelpSystemContext';
import { initIdentityProvider } from '../../../api/useIdentityProvider';
import { Prompt } from "react-router";
import _ from "lodash";


function IdentityProviderCreateComponent() {

    const history = useHistory();

    const [errors, setErrors] = useState(initIdentityProvider);
    const [isBlocking, setIsBlocking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [identityProvider, setIdentityProvider] = useState(initIdentityProvider);

    const handleChange = function handleChangeOnCreateIdpForm(event) {
        const { name, value } = event.target;
        setIdentityProvider((prevData) => ({ ...prevData, [name]: value }));
    };

    // Compare identity provider with initial identity provider on mount and whenever identity provider changes
    useEffect(() => {
        const hasIdpChanged = !_.isEqual(identityProvider, initIdentityProvider);
        setIsBlocking(hasIdpChanged);
    }, [identityProvider]);

    const onSubmit = async function createIdentityProviderForm() {
        setSubmitting(true);
        try {
            await axios.post(api_routes.identityProvider.endpoint, identityProvider);
            toast.notify(({onClose}) =>
                <CustomAlert message={`Created identity provider ${identityProvider.name}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        } finally{
            setSubmitting(false);
        }
    }

    const handleCancel = function handleCancel() {
        history.push(routes.settings.path);
    };

    return (<Box p={3}>
            <Typography variant='h5'>{routes.createIdentityProvider.pageTitle}</Typography>
            <HelpSystemContext.Consumer>
                {open =>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={open ? 12 : 6} xl={open ? 6 : 4}>
                            <IdentityProviderForm handleSubmit={onSubmit}
                                                  handleCancel={handleCancel}
                                                  handleChange={handleChange}
                                                  identityProvider={identityProvider}
                                                  errors={errors}/>
                            <Prompt when={!submitting && isBlocking}
                                message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>
                        </Grid>
                        <Grid item xs={12} md={open ? 12 : 6} xl={open ? 6 : 8}>
                            <IdentityProviderConfigInfo registrationId={identityProvider.registrationId}/>
                        </Grid>
                    </Grid>
                }
            </HelpSystemContext.Consumer>
        </Box>
    );
}

IdentityProviderCreateComponent.propTypes = {
    saveIdentityProvider: PropTypes.func,
};

IdentityProviderCreateComponent.defaultProps = {};

export default IdentityProviderCreateComponent;
