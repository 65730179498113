import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { Prompt, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import routes from '../../../util/routes';
import { convertErrorsToObject } from '../../../util/errorHandler';
import { IdentityProviderConfigInfo } from './IdentityProviderConfigInfo';
import HelpSystemContext from '../../../context/HelpSystemContext';
import CustomAlert from '../../common/CustomAlert';
import IdentityProviderForm from './IdentityProviderForm';
import useIdentityProvider from '../../../api/useIdentityProvider';
import _ from "lodash";


function IdentityProviderUpdateComponent() {

    const history = useHistory();
    const {identityProviderId} = useParams();
    const {
        isLoading: loadingIdentityProvider,
        identityProvider: fetchedIdentityProvider,
        updateIdentityProvider
    } = useIdentityProvider({params: {identityProviderId}});
    const [identityProvider, setIdentityProvider] = useState(fetchedIdentityProvider);
    const [isBlocking, setIsBlocking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setIdentityProvider(fetchedIdentityProvider);
    }, [fetchedIdentityProvider]);

    const handleSubmit = async function updateIdentityProviderForm() {
        setSubmitting(true);
        try {
            await updateIdentityProvider(identityProvider);
            setErrors({});
            toast.notify(({onClose}) =>
                <CustomAlert message={`${identityProvider.name} identity provider updated successfully.`}
                             onClose={onClose}/>);
            setIsBlocking(false);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        } finally {
            setSubmitting(false);
        }
    }

    const handleChange = function handleChangeOnUpdateIdpForm(event) {
        setIdentityProvider({...identityProvider, [event.target.name]: event.target.value});
    }

    const handleCheckboxChange = function handleCheckboxChangeOnUpdateIdpForm(name, event) {
        setIdentityProvider({...identityProvider, [name]: event.target.checked});
    }

    // Compare identity provider with fetched identity provider on mount and whenever identity provider changes
    useEffect(() => {
        const hasIdpChanged = !_.isEqual(identityProvider, fetchedIdentityProvider);
        setIsBlocking(hasIdpChanged);
    }, [identityProvider, fetchedIdentityProvider]);

    const handleCancel = function handleCancel() {
        history.push(routes.settings.path);
    }

    return (<Box p={3}>
            <Typography variant={'h5'}>{routes.updateIdentityProvider.pageTitle}</Typography>
            <HelpSystemContext.Consumer>
                {open =>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={open ? 12 : 6} xl={open ? 6 : 4}>
                            {!loadingIdentityProvider &&
                                <IdentityProviderForm handleSubmit={handleSubmit}
                                                      handleChange={handleChange}
                                                      handleCheckboxChange={handleCheckboxChange}
                                                      handleCancel={handleCancel}
                                                      identityProvider={identityProvider}
                                                      errors={errors}
                                />
                            }
                            <Prompt when={!submitting && isBlocking}
                                    message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>
                        </Grid>
                        <Grid item xs={12} md={open ? 12 : 6} xl={open ? 6 : 8}>
                            <IdentityProviderConfigInfo registrationId={identityProvider.registrationId}/>
                        </Grid>
                    </Grid>
                }
            </HelpSystemContext.Consumer>
        </Box>
    )
}

IdentityProviderUpdateComponent.propTypes = {
    identityProvider: PropTypes.object,
};

IdentityProviderUpdateComponent.defaultProps = {};

export default IdentityProviderUpdateComponent;
