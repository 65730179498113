import React, { useState } from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Grid, IconButton, List, ListItemText, Divider, ListSubheader, Menu, MenuItem, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import withStyles from '@mui/styles/withStyles';
import {StyledFlexGridItem} from './ResponsiveDrawer';
import {Role} from '../auth/accessControl/role';
import AccessControl from '../auth/accessControl/AccessControl';
import routes from '../../util/routes';
import {KEY_USER_LOGIN_METHOD, VALUE_USER_LOGIN_METHOD_SLINK} from '../../container/auth/authenticationService';

const StyledMenu = withStyles({
    paper: {
        border: `1px solid ${grey[300]}`,
    },
})((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            fontSize: '0.85rem',
        },
        '&.active': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                borderBottom: `3px solid ${theme.palette.secondary.main}`,
                width: 'fit-content',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
}))(MenuItem);

const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;

function MobileTopBarNavigation(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event) => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    return (
        <StyledFlexGridItem item>
            <Grid container justifyContent='flex-end' alignItems={'center'}>
                <Grid item>
                    {props.helpTextIconButton}
                </Grid>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Grid item sx={{ml: 1}}>
                    <IconButton onClick={openMenu} size='small' sx={{color: grey[600]}}><StyledMenuIcon/></IconButton>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                    >
                        <List component='nav'
                              subheader={
                                  <ListSubheader style={{padding: '8px 16px'}} component='div' id='nav-subheader'>
                                      <Typography variant='body2'>Hi, {props.currentUser.username}</Typography>
                                  </ListSubheader>
                              }
                        >
                        </List>
                        <Divider/>
                        <List component='nav' aria-labelledby='nav-subheader'>
                            <AccessControl allowedRoles={[Role.ADMIN]}>
                                <StyledMenuItem component={NavLink} onClick={closeMenu}
                                                to={routes.filesystemfunc.path('')}>
                                    <ListItemText primary={routes.filesystem.menuTitle}/>
                                </StyledMenuItem>
                            </AccessControl>
                            <AccessControl allowedRoles={[Role.ADMIN]}>
                                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.users.path}>
                                    <ListItemText primary={routes.users.menuTitle}/>
                                </StyledMenuItem>
                            </AccessControl>
                            <AccessControl allowedRoles={[Role.ADMIN]}>
                                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.settings.path}>
                                    <ListItemText primary={routes.settings.menuTitle}/>
                                </StyledMenuItem>
                            </AccessControl>
                        </List>
                        <AccessControl allowedRoles={[Role.ADMIN]}>
                            <Divider/>
                        </AccessControl>
                        <List component='nav'>
                            {localStorage.getItem(KEY_USER_LOGIN_METHOD) === VALUE_USER_LOGIN_METHOD_SLINK &&
                                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.profile.path}>
                                    <ListItemText primary={routes.profile.menuTitle}/>
                                </StyledMenuItem>
                            }
                            <StyledMenuItem onClick={props.handleSignOut}>
                                <ListItemText primary='Log Out'/>
                            </StyledMenuItem>
                        </List>
                    </StyledMenu>

                </Grid>
            </Grid>
        </StyledFlexGridItem>
    );
}

export default MobileTopBarNavigation;
