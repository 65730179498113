import React from 'react';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import cloud_providers from '../../util/cloud_providers';
import styled from 'styled-components';
import { useTheme } from "@mui/material/styles";

const {cloudProvider, version} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;
const VERSION = version || process.env.REACT_APP_VERSION;

const StyledLogo = styled.img`
  width: 200px;
`;


function ServerStartingUp() {

    const getDocumentationLink = () => {
        return cloud_providers.filter(provider => provider.name === CLOUD_PROVIDER)[0].documentationUrl;
    }

    const theme = useTheme();

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center' className='setting-up-screen'>
            <Grid item container justifyContent='center' alignItems='center'
                  direction='column' style={{marginBottom: '32px'}}>
              <StyledLogo src={theme.clientBranding.logo.fullImage}
                          alt={theme.clientBranding.logo.alt}/>
            </Grid>
            <Grid item>
                <Typography>
                    Please wait while StorageLink finishes setting up and try again in a minute...
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    Meanwhile, take a moment to view
                    our <Link href={getDocumentationLink()} target='_blank'>documentation</Link> for
                    setting up StorageLink version {VERSION}.
                </Typography>
            </Grid>
        </Grid>
    );
}

ServerStartingUp.propTypes = {};

ServerStartingUp.defaultProps = {};

export default ServerStartingUp;
