import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import FormControlSelect from '../common/FormControlSelect';
import { AWS, cloudProviderNames } from '../../util/cloud_providers';


function CloudConnectionCredentialsListDropDown(props) {

    const {cloudProvider, setCloudCredentialsId, credentials, cloudCredentialsId} = props;

    return (<Box mt={3} mb={1}>
        <FormControlSelect name='cloudCredentialsId'
                           type='select'
                           label={`Saved ${cloudProviderNames[cloudProvider]} Cloud Credentials`}
                           value={cloudCredentialsId}
                           onChange={(event) => setCloudCredentialsId(event.target.value)}
                           placeholder={'Select Saved Cloud Credentials'}
                           margin={'none'}
                           width={'100'}
        >
            {credentials.length === 0 && <MenuItem value={'-1'} key={'-1'}>
                {`No Saved ${cloudProviderNames[cloudProvider]} Cloud Credentials Found`}</MenuItem>}
            {(credentials.length !== 0) && credentials.map(c =>
                <MenuItem value={c.id} key={c.id}>{c.displayName}</MenuItem>)}
        </FormControlSelect>
    </Box>);
}

CloudConnectionCredentialsListDropDown.propTypes = {
    cloudProvider: PropTypes.string,
    setCloudCredentialsId: PropTypes.func,
    credentials: PropTypes.array,
    cloudCredentialsId: PropTypes.any,
};

CloudConnectionCredentialsListDropDown.defaultProps = {
    cloudProvider: AWS,
    setCloudCredentialsId: () => {
    },
    credentials: [],
};

export default CloudConnectionCredentialsListDropDown;
