import React, { useState } from "react";
import PageSection from "../../common/PageSection";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Grid,
    Input,
    InputAdornment,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import CircularProgressButton from "../../common/CircularProgressButton";
import HelpSystemContext from '../../../context/HelpSystemContext';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import api_routes from "../../../util/api_routes";
import httpStatus from "../../../util/http_status";
import styled from "styled-components";
import { any, arrayOf, func, oneOf, shape, string } from "prop-types";

const useStyles = makeStyles((theme) => ({
    statusAccordion: {
        marginTop: theme.spacing(2),
    },
    statusBadge: {
        marginLeft: theme.spacing(1)
    },
    statusDetails: {
        backgroundColor: '#f8f8f8',
        height: '300px',
        overflow: 'scroll'
    },
    uploadField: {
        marginTop: theme.spacing(1)
    }
}));

export const downloadBackupArtifact = (content) => {
    const url = window.URL.createObjectURL(
        new Blob([content.data], {type: content.headers['content-type']}))
    const link = document.createElement('a');
    link.href = url;
    const suggestedFileName = content.headers["content-disposition"].split("filename=")[1];
    const effectiveFileName = (suggestedFileName === undefined
        ? "StorageLinkBackup.yaml"
        : suggestedFileName.replaceAll('"', ''));
    link.setAttribute('download', effectiveFileName);
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
};


const StyledInput = styled(Input)`
  background-color: ${props => {
    let color;
    if (props.importcomplete === 'true') {
      color = `rgba(0, 255, 0, 0.15)`;
    } else {
      color = 'rgba(0,0,0,0.02)';
    }
    return color;
  }};
  color: ${props => {
    let color;
    if (props.importcomplete === ' true') {
      color = `rgb(11, 121, 11)`;
    } else {
      color = '#333';
    }
    return color;
  }};
  font-size: 14px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    cursor: pointer;
  }

  input[type=file]::-webkit-file-upload-button {
    display: none;
  }

  input[type=file] {
    opacity: 1
  }
`;

function SettingsComponentBackupRecovery() {

    const classes = useStyles();
    const theme = useTheme();

    const exportOptions = ['Export Backup File', 'Export Support Backup File']

    const [expanded, setExpanded] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [loadingImportArtifact, setLoadingImportArtifact] = useState(false);
    const [importStatus, setImportStatus] = useState({
        complete: false,
        message: null
    });
    const [selectedFile, setSelectedFile] = useState({
        file: undefined,
        name: undefined
    });

    const handleExportArtifact = async (event, index) => {
        const params = (index === 1 ? {support: true} : null);
        try {
            let response = await axios.get(
                `${api_routes.backup.endpoint}`, {
                    params: {...params},
                    responseType: 'blob'
                },
            );
            downloadBackupArtifact(response);
        } catch (error) {
            if (error.response && error.response.status === httpStatus.badRequest) {
                setImportStatus({
                    complete: true,
                    message: [error.response.data.message],
                });
            } else {
                alert("Import failed due to: " + error);
                console.error(error);
            }
        } finally {
            setAnchorEl(null);
        }
    };

    const handleImportArtifact = () => {
        let reader = new FileReader();
        reader.onload = async function readOnLoad(e) {
            setLoadingImportArtifact(true);
            let contents = e.target.result;
            try {
                const response = await axios.post(`${api_routes.backup.endpoint}`,
                    Buffer.from(contents).toString("utf-8"),
                    {
                        headers:
                            {'content-type': 'text/json'}
                    }
                )
                if (response.status === httpStatus.ok) {
                    setImportStatus({
                        complete: true,
                        message: response.data
                    });

                }
            } catch (error) {
                alert("Import failed due to: " + error);
                console.error(error);
            } finally {
                setLoadingImportArtifact(false);
                // props.refresh()
            }

        };
        reader.readAsText(selectedFile.file, "utf-8");
    };

    const handleSelectFile = (event) => {
        let inputFile = event.target.files[0];
        setImportStatus({
            complete: false,
            message: null
        });
        setSelectedFile({
            file: inputFile,
            name: inputFile?.name
        });
    };
    const expandSection = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <HelpSystemContext.Consumer>
            {() => <>
                <PageSection title={'Backup & Recovery'}
                             subtitle={'Import and export YAML artifact files for backup and recovery.'}
                />
                <Grid container alignItems='center'>
                    <CircularProgressButton size='small'
                                            onClick={(event) => setAnchorEl(event.currentTarget)}
                                            buttonTextTransform='none' label='Export'
                                            aria-controls='user-menu'
                                            aria-haspopup='true'
                                            endIcon={<ArrowDropDownIcon />}
                    />
                    <Menu id='user-menu'
                          keepMounted
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                          }}
                    >
                        {exportOptions.map((option, index) => (
                            <MenuItem
                                theme={theme}
                                key={option}
                                onClick={(event) => handleExportArtifact(event, index)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid container alignItems='center'>
                    <StyledInput type='file' importcomplete={importStatus.complete.toString()}
                                 id='backupUpload'
                                 name='backupupload'
                                 inputProps={{accept: '.yaml,.yml'}}
                                 className={classes.uploadField}
                                 onChange={handleSelectFile}
                                 startAdornment={
                                     <InputAdornment position="start" onClick={() => document.getElementById('backupUpload')?.click()}>
                                         <UploadFileIcon/>
                                     </InputAdornment>
                                 }
                    />
                    <CircularProgressButton size='small'
                                            inProgress={loadingImportArtifact}
                                            onClick={handleImportArtifact}
                                            buttonTextTransform='none' label='Import'
                                            disabled={selectedFile.file === undefined}
                    />
                </Grid>
                <p style={{'display': importStatus.complete?'block':'none'}}>Import complete, refresh your browser to see changes after reviewing import results.</p>
                <ImportStatusAccordion expanded={expanded} statusType={'error'} badgeColor={'error'}
                                       onChange={expandSection('error')}
                                       importStatus={importStatus.message?.error}/>

                <ImportStatusAccordion expanded={expanded} statusType={'warning'} badgeColor={'warning'}
                                       onChange={expandSection('warning')}
                                       importStatus={importStatus.message?.warning}/>
                <ImportStatusAccordion expanded={expanded} statusType={'skipped'} badgeColor={'info'}
                                       onChange={expandSection('skipped')}
                                       importStatus={importStatus.message?.skipped}/>
                <ImportStatusAccordion expanded={expanded} statusType={'success'} badgeColor={'success'}
                                       onChange={expandSection('success')}
                                       importStatus={importStatus.message?.success}/>

            </>}
        </HelpSystemContext.Consumer>
    )
}

SettingsComponentBackupRecovery.propTypes = {};

SettingsComponentBackupRecovery.defaultProps = {};

function ImportStatusAccordion(props) {

    const classes = useStyles();

    const {importStatus, statusType, expanded, badgeColor, onChange} = props;
    return <Accordion className={classes.statusAccordion}
                      expanded={expanded === statusType}
                      onChange={onChange}
    >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
        >
            <Typography sx={{textTransform: 'capitalize'}} variant={"h6"}>{statusType}</Typography>
            <Badge className={classes.statusBadge}
                   badgeContent={importStatus?.length} color={badgeColor}
                   overlap={"rectangular"}/>
        </AccordionSummary>
        <AccordionDetails className={classes.statusDetails}>
            {importStatus?.length
                ? importStatus.map(status =>
                    <Typography key={`<${status.entity}> ${status.name}`}>
                        <b>{`<${status.entity}> ${status.name}: `} </b>{`${status.message}`}
                    </Typography>
                )
                : <Typography>
                    {`No ${statusType} during import`}
                </Typography>
            }
        </AccordionDetails>
    </Accordion>;
}

ImportStatusAccordion.propTypes = {
    statusType: oneOf(['error', 'warning', 'skipped', 'success']),
    expanded: any,
    badgeColor: oneOf(["secondary", "default", "success", "warning", "error", "primary", "info"]),
    onChange: func,
    importStatus: arrayOf(shape({
        entity: string,
        name: string,
        message: string
    }))
};


export default SettingsComponentBackupRecovery;