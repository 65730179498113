import React, { useState } from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import 'moment/min/locales';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Box, Hidden, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Pending } from '@mui/icons-material';
import CloudTwoToneIcon from '@mui/icons-material/FolderSpecialTwoTone';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import FolderOpenTwoToneIcon from '@mui/icons-material/FolderOpenTwoTone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { isEmpty, isEmptyArray, isEmptyObject } from '../../util/helpers';
import mimeTypes from '../../util/content_types.json';
import CircularProgressButton from '../common/CircularProgressButton';
import { StyledIsActiveDropZone } from '../common/styled';
import _ from "lodash";
import FormControlCheckbox from "../common/FormControlCheckbox";

export const iconStyle = {verticalAlign: 'bottom', display: 'inline-flex'};
export const fileIconDict = {
    'regular': <InsertDriveFileTwoToneIcon color='primary' sx={iconStyle}/>,
    'directory': <FolderTwoToneIcon color='primary' sx={iconStyle}/>,
    'cloud': <CloudTwoToneIcon color={'secondary'} sx={iconStyle}/>,
    'open': <FolderOpenTwoToneIcon color={'primary'} sx={iconStyle}/>
}

function FilesystemTableComponent(props) {
    const theme = useTheme();

    const {
        deleting,
        selection,
        setSelection,
        changeFolder,
        downloadSelected,
        downloadSingleRow,
        availableActions,
        uploadFile,
        uploadable
    } = props;
    const [shiftStartIndex, setShiftStartIndex] = useState(1);
    const [invertSelection, setInvertSelection] = useState(false);

    function formatSize(size) {
        if (isEmpty(size)) {
            return;
        }

        const KB = 1_000;
        const MB = KB * KB;
        const GB = MB * KB;
        const locale = window.navigator.userLanguage || window.navigator.language;
        const localeStringOptions = {maximumFractionDigits: 2, minimumFractionDigits: 0};

        if (size > GB) {
            return `${(size / GB).toLocaleString(locale, localeStringOptions)} GB`;
        } else if (size > MB) {
            return `${(size / MB).toLocaleString(locale, localeStringOptions)} MB`;
        } else if (size > KB) {
            return `${(size / KB).toLocaleString(locale, localeStringOptions)} KB`;
        } else {
            return `${size.toLocaleString(locale, localeStringOptions)} B`;
        }
    }

    function getDisplayName(row) {
        if (row.isParentFolder) {
            return '..';
        } else if (row.isCurrentFolder) {
            return row.absolutePath;
        }
        return row.name;
    }

    const handleCheckboxClick = (event, row) => {
        event.stopPropagation();
        handleRowClick({detail: 1, shiftKey: false, metaKey: true, ctrlKey: true}, row);
    }

    const handleRowClick = (event, row) => {
        if (event.detail === 2 && !isEmptyObject(availableActions)) {
            if (isEmptyArray(selection)) {
                if (row.type.toLowerCase() === 'directory' && !row.isParentFolder && !row.isCurrentFolder){
                    changeFolder(row);
                } else if (row.type.toLowerCase() === 'regular' && row.currentPermissions.downloadable) {
                    downloadSingleRow(row);
                }
            } else if (availableActions.openFolder) {
                if (!deleting && _.eq(selection[0],row)) {
                    changeFolder(selection[0]);
                }
            } else if (availableActions.download) {
                downloadSelected();
            }
        } else {
            let newSelection;
            if (event.shiftKey) {
                const shiftEndIndex = props.rows.findIndex(r => _.eq(r, row));
                if (shiftStartIndex <= shiftEndIndex) {
                    newSelection = props.rows.slice(shiftStartIndex, shiftEndIndex + 1);
                } else {
                    newSelection = props.rows.slice(shiftEndIndex, shiftStartIndex + 1);
                }
            } else {
                setShiftStartIndex(props.rows.findIndex(r => _.eq(r, row)));
                setInvertSelection(_.includes(selection, row));
                newSelection = [row];
            }

            if (event.metaKey || event.ctrlKey) {
                if (event.shiftKey ? invertSelection : _.includes(selection, row)) {
                    setSelection(_.filter(selection, r => !_.includes(newSelection, r)));
                } else {
                    setSelection(_.uniq([...selection, ...newSelection]));
                }
            } else {
                setInvertSelection(false);
                setSelection([...newSelection]);
            }
        }
    }

    function getIcon(row) {
        if (row.cloudConnectionId && !row.isParentFolder && !row.isCurrentFolder) {
            return fileIconDict['cloud'];
        }
        if (row.isCurrentFolder) {
            return fileIconDict['open'];
        }

        return fileIconDict[row.type.toLowerCase()];
    }

    function getLeftIndent(row) {
        if (row.isCurrentFolder) {
            return 0
        }
        return theme.spacing(1)
    }

    const [{canDrop, isOver}, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item) {
                uploadFile(null, item.items).then();
            },
            canDrop() {
                return uploadable;
            },
            hover(item) {
            },
            collect: (monitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                }
            },
        }),
        [uploadFile, uploadable],
    )
    const isActive = canDrop && isOver;

    return (
        <StyledIsActiveDropZone theme={theme}
                                isActive={isActive}
                                notUploadable={!canDrop && isOver}
                                ref={drop}
                                style={{
                                    height: 'calc(100vh - 274px)', position: 'relative', display: 'flex',
                                    flexDirection: 'column', flexGrow: 1, marginTop: theme.spacing(1)
                                }}>
            <TableContainer>
                <Table sx={{width: '100%', tableLayout: 'fixed'}} aria-label='filesystem table'>
                    <TableBody style={{userSelect: 'none'}}>
                        {!props.loadingFilesystem && !deleting && props.rows.filter(row => !row.isParentFolder).map((row) => (
                            <TableRow
                                key={getDisplayName(row)}
                                sx={{
                                    '&:last-child td, &:last-child th': {border: 0},
                                    '& td': {border: 'none', padding: '.15rem .1rem'},
                                    '& td:last-child': {paddingRight: '1rem'},
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.action.hover,
                                    }
                                }}
                                onClick={(event) => handleRowClick(event, row)}
                                selected={!isEmptyArray(selection) && _.includes(selection, row)}
                            >
                                <TableCell className={"multiselectCheckboxCell"} align={"center"}
                                           onClick={(event) => handleCheckboxClick(event, row)}>
                                    <FormControlCheckbox
                                        sx={{"&": {margin: "0 !important"},
                                            "& span": {padding: "4px !important"}}}
                                        checked={!isEmptyArray(selection) && _.includes(selection, row)}></FormControlCheckbox></TableCell>
                                <TableCell
                                    align='left'
                                    sx={{
                                        padding: '8px', // Add padding to table data cells
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'inline-block',
                                            paddingLeft: getLeftIndent(row),
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: 'block',
                                                whiteSpace: 'normal'
                                            }}
                                            title={getDisplayName(row)}
                                        >
                                            {getIcon(row)} {getDisplayName(row)}
                                        </span>
                                    </Box>
                                </TableCell>
                                <Hidden smDown>
                                    <TableCell sx={{
                                        overflow: 'visible',
                                        whiteSpace: 'normal',
                                        width: '12.5%'
                                    }}
                                        align='right'
                                        title={row.contentType in mimeTypes ? mimeTypes[row.contentType].description : row.contentType}
                                    >
                                        {row.contentType in mimeTypes ? mimeTypes[row.contentType].description : row.contentType}
                                    </TableCell>
                                </Hidden>

                                <Hidden mdDown>
                                    <TableCell style={{width: '12.5%'}}
                                        align='right'>{row.type.toLowerCase() === 'regular' ? formatSize(row.size) : ''}</TableCell>
                                </Hidden>

                                <Hidden smDown>
                                    <TableCell style={{width: '225px'}}
                                        align='right'>{moment(row.lastModified).locale(window.navigator.userLanguage || window.navigator.language).format('ll LTS')}</TableCell>
                                </Hidden>

                                <Hidden smUp>
                                    <TableCell align='right' style={{width: '110px'}}>
                                        {moment(row.lastModified).locale(window.navigator.userLanguage || window.navigator.language).format('L')}
                                    </TableCell>
                                </Hidden>
                            </TableRow>
                        ))}
                        {(props.loadingFilesystem || deleting) &&
                            <TableRow sx={{'& td': {border: 'none', padding: '.15rem .1rem'}}}>
                                <TableCell colSpan={4} align='center'><i>{deleting ? "Deleting": "Loading"}</i></TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {props.hasMorePages ?
                <CircularProgressButton
                    label={props.hasMorePages ? 'Load More' : 'All files loaded'}
                    fullWidth={false}
                    buttonTextTransform={'none'}
                    width={'fit-content'}
                    ml={'auto'}
                    mr={'auto'}
                    size={'small'}
                    style={{borderRadius: '20px'}}
                    disabled={!props.hasMorePages}
                    inProgress={props.isRefreshing}
                    startIcon={props.hasMorePages ? <Pending/> : ''}
                    onClick={() => props.setNumPages(props.numPages + 1)}
                /> : null}
            {(!props.loadingFilesystem && !deleting && props.rows.filter(row => !row.isParentFolder && !row.isCurrentFolder).length === 0) &&
                <Box display='flex' justifyContent='center' alignItems='center' sx={{flexGrow: '1'}}
                     flexDirection='column'>
                    {props.uploadable && <>
                        <Box>
                            <FileUploadOutlinedIcon fontSize='large' color='primary'
                                                    sx={!isActive ? {
                                                        height: '100px',
                                                        width: '100px',
                                                        border: `1px solid ${theme.palette.grey.A200}`,
                                                        borderRadius: '25px',
                                                        backgroundColor: `${theme.palette.grey.A200}`
                                                    } : {
                                                        height: '100px',
                                                        width: '100px'
                                                    }}
                            />
                        </Box>
                        <Box mt={3}>
                            Drop Files and Folders here
                        </Box>
                    </>}
                    {!props.uploadable &&
                        <Box>
                            File upload is not available
                        </Box>
                    }
                </Box>
            }
        </StyledIsActiveDropZone>
    );
}

FilesystemTableComponent.propTypes = {};

FilesystemTableComponent.defaultProps = {};

export default FilesystemTableComponent;
