import toast from "toasted-notes";
import CustomAlert from "../component/common/CustomAlert";
import React from "react";

const copyTextToClipboard = (event, text) => {
  event.stopPropagation()
  navigator.clipboard.writeText(text).then(() => {
    toast.notify(({onClose}) => <CustomAlert type='success' message='Text copied!' onClose={onClose}/>);
  });
};

export default copyTextToClipboard;