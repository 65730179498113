import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import UserForm from './UserForm';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import { convertErrorsToObject } from '../../util/errorHandler';
import { isEmpty, stripStartingAndTrailingSlashes } from '../../util/helpers';
import CustomAlert from '../common/CustomAlert';
import HelpSystemContext from '../../context/HelpSystemContext';
import { initUser } from '../../api/useUser';
import { Role, RoleDisplayDict } from '../auth/accessControl/role';


function UserCreateComponent() {

    const history = useHistory();

    const [user, setUser] = useState(initUser);
    const [homeFolderManualChange, setHomeFolderManualChange] = useState(false); // Flag to indicate that user typed in a custom home folder path.
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user.role === Role.USER && !homeFolderManualChange) {
            setUser(prevState => ({...prevState, homeFolderPath: `/users/${user.username}`}));
        } else if (user.role === Role.ADMIN && !homeFolderManualChange) {
            setUser(prevState => ({...prevState, homeFolderPath: `/`}));
        }
    }, [homeFolderManualChange, user.username, user.role]);

    const handleChange = function handleTextChangeOnUserForm(event) {
        if (event.target && event.target.name === 'homeFolderPath') {
            setHomeFolderManualChange(true);
        }
        if (event.target) {
            setUser({...user, [event.target.name]: event.target.value});
        }
    }

    const handleCheckboxChange = function handleCheckboxChangeOnCreateUserForm(name, event) {
        setUser({...user, [name]: event.target.checked});
    }

    const handleFolderPermissionChange = function handleFolderPermissionChange(name, event) {
        setUser({...user, homeFolderPermissions: {...user.homeFolderPermissions, [name]: event.target.checked}});
    }

    const handleSubmit = async function handleSaveUser() {
        try {
            await axios.post(api_routes.user.endpoint, user);
            toast.notify(({onClose}) => <CustomAlert
                message={`New ${user.role ? RoleDisplayDict[user.role] : 'user'} added!`} onClose={onClose}/>);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
            throw error
        }
    };

    const handleCancel = function handleCancel() {
        history.push(routes.users.path);
    };

    const saveFolder = async function createFolder({folderName, cloudConnectionId, homeFolderPath}) {
        if (!isEmpty(folderName)) {
            let split = stripStartingAndTrailingSlashes(homeFolderPath).split("/");
            let splicedStr = split.slice(0, split.length - 1).join("/");
            let filesystem_api = `${api_routes.filesystem.endpoint}/${splicedStr}`;
            return await axios.post(filesystem_api,
                {folderName, connectionId: cloudConnectionId},
                {headers: {'Content-Type': 'application/json'}}
            );
        }
    };

    return (<Box p={3}>
        <Typography variant='h5'>{routes.createUser.pageTitle}</Typography>
        <HelpSystemContext.Consumer>
            {open => <Grid container>
                <Grid item xs={12} md={open ? 12 : 8} xl={8}>
                    <UserForm handleChange={handleChange}
                              handleCheckboxChange={handleCheckboxChange}
                              handleFolderPermissionChange={handleFolderPermissionChange}
                              handleSubmit={handleSubmit}
                              handleCancel={handleCancel}
                              user={user}
                              initialUser={initUser}
                              errors={errors}
                              saveFolder={saveFolder}
                              homeFolderManualChange={homeFolderManualChange}
                    />
                </Grid>
            </Grid>
            }
        </HelpSystemContext.Consumer>
    </Box>);
}

export default UserCreateComponent;
