import React from 'react';
import PropTypes from "prop-types";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import useTheme from '@mui/material/styles/useTheme'

import Divider from '@mui/material/Divider';
import styled from "styled-components";
import { Typography } from "@mui/material";
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

const StyledTypographyLabel = styled(Typography)`
  //noinspection CssInvalidPropertyValue
  color: "#000000cf";
  letter-spacing: 0;
`;

function UserPermissionsComponent(props) {

  const theme = useTheme();

  const { userPermissions, handleChange } = props;


  const onChange = (event, perm) => {
    let existingPerm = userPermissions.filter(value => value.userId === perm.userId).pop();
    if (existingPerm) {
      existingPerm.permissions[event.target.name] = event.target.checked;
    }
    handleChange({
      target: {
        value: [...userPermissions]
      }
    });
  };


  return <Box mt={2} mb={1}>
    <>
    <StyledTypographyLabel filled={"true"} variant={"body1"}>User Permissions</StyledTypographyLabel>
    {userPermissions?.length > 0  ?? 'Only administrators can access this folder.'}
    <TableContainer>
      <Table>
        <TableBody>
          {!(userPermissions && userPermissions?.length > 0)  ?
          <TableRow>
            <TableCell>
               Only administrators can access this folder.
            </TableCell>
          </TableRow> : null
          }
          {userPermissions?.map(perm =>
            <TableRow key={perm.username} sx={{ '&:hover': { backgroundColor: theme.palette.action.hover } }}>
              <TableCell>
                <StyledTypographyLabel>{perm.username}</StyledTypographyLabel>
              </TableCell>
              <TableCell align="right">
                <FormControl component="fieldset" variant="standard" error={true}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    {perm.isHomeFolder && <><HomeTwoToneIcon color={'primary'}/> <StyledTypographyLabel sx={{mr:2}}>Home Folder</StyledTypographyLabel></>}
                    {!perm.isHomeFolder && <FormControlLabel
                      control={<Checkbox checked={perm.permissions.inheritable} name="inheritable"/>}
                      title={perm.isHomeFolder ? "Cannot inherit permissions, this is the user's home folder" : "Mark to inherit permissions from ancestor folder"}
                      label="Inherited" disabled={perm.isHomeFolder}
                      onChange={(event) => onChange(event, perm)}
                    />}
                    <Divider orientation="vertical" flexItem sx={{ mr: 2 }}/>
                    <FormControlLabel control={<Checkbox checked={perm.permissions.listable} name="listable"/>}
                                      label="List"
                                      disabled={perm.permissions.inheritable}
                                      onChange={(event) => onChange(event, perm)}
                    />
                    <FormControlLabel control={<Checkbox checked={perm.permissions.downloadable} name="downloadable"/>}
                                      label="Download"
                                      disabled={perm.permissions.inheritable}
                                      onChange={(event) => onChange(event, perm)}
                    />
                    <FormControlLabel control={<Checkbox checked={perm.permissions.uploadable} name="uploadable"/>}
                                      label="Upload"
                                      disabled={perm.permissions.inheritable}
                                      onChange={(event) => onChange(event, perm)}
                    />
                    <FormControlLabel control={<Checkbox checked={perm.permissions.deletable} name="deletable"/>}
                                      label="Delete/Overwrite"
                                      disabled={perm.permissions.inheritable}
                                      onChange={(event) => onChange(event, perm)}
                    />
                  </Box>
                </FormControl>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  </Box>;
}

const Permissions = PropTypes.shape({
  downloadable: PropTypes.bool,
  inheritable: PropTypes.bool,
  uploadable: PropTypes.bool,
  deletable: PropTypes.bool,
  listable: PropTypes.bool
})

const UserPermissions = PropTypes.shape({
  userId: PropTypes.any,
  username: PropTypes.string,
  isHomeFolder: PropTypes.bool,
  permissions: Permissions
})

UserPermissionsComponent.propTypes = {
  userPermissions: PropTypes.arrayOf(UserPermissions),
  handleChange: PropTypes.func
};

UserPermissionsComponent.defaultProps = {};

export default UserPermissionsComponent;