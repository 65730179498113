import { useEffect, useState } from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initParams = {
    identityProviderId: 0,
};

export const initIdentityProvider = {
    name: '',
    type: 'USER',
    notes: '',
    oidcConfigEndpoint: '',
    clientId: '',
    clientSecret: '',
    registrationId: '',
    nameAttribute: '',
    scopes: '',
    issuerUri: '',
};


function useIdentityProvider({ params = initParams } = {}) {

    const { identityProviderId } = params;
    const [identityProvider, setIdentityProvider] = useState(initIdentityProvider);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadIdentityProvider() {
        const fetchIdentityProviderData = async function fetchIdentityProviderData() {
            try {
                const result = await axios.get(`${api_routes.identityProvider.endpoint}/${identityProviderId}`);
                setIdentityProvider(result.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error)
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchIdentityProviderData();
    }, [identityProviderId]);

    const updateIdentityProvider = async function updateIdentityProviderApi(identityProvider) {
        return await axios.put(`${api_routes.identityProvider.endpoint}/${identityProviderId}`, identityProvider);
    }

    return {isLoading, identityProvider, updateIdentityProvider};
}

export default useIdentityProvider;
