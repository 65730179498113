const routes = {
    home: {path: '/', menuTitle: '', pageTitle: ''},
    homeAdmin: {path: '/filesystem/'},
    homeSwift: {path: '/filesystem/'},
    settings: {path: '/settings', menuTitle: 'Settings', pageTitle: 'Settings'},
    cloudCredentials: {path: '/cloudcredentials'},
    createCloudCredentials: {path: '/new', pageTitle: (provider) => `Add ${provider} Cloud Credentials`},
    updateCloudCredentials: {path: '/edit', pageTitle: (provider) => `Edit ${provider} Cloud Credentials`},
    users: {path: '/users', menuTitle: 'Users', pageTitle: 'User Management'},
    createUser: {path: '/new', pageTitle: 'Add User'},
    updateUser: {path: '/edit', pageTitle: 'Edit User'},
    profile: {path: '/changepassword', menuTitle: 'Change Password'},
    diagnostics: {path: '/diagnostics', menuTitle: 'Diagnostics', pageTitle: 'Diagnostics'},
    filesystem: {path: '/filesystem/*', menuTitle: 'Folders', pathTitle: ''},
    filesystemfunc: {path: (path) => `/filesystem/${path}`},
    filesystemCreateFolder: {path: `/fs/folder/new`},
    filesystemfuncCreateFolder: {path: (path) => `/fs/folder/new?path=${path}`},
    filesystemUpdateFolder: {path: `/fs/folder/update`},
    filesystemfuncUpdateFolder: {path: (path) => `/fs/folder/update?path=${path}`},
    kb: {path: 'http://storagelink.co/kb', menuTitle: 'Knowledge Base'},
    identityProviders: {path: '/identityproviders'},
    createIdentityProvider: {path: '/new', pageTitle: `Add Identity Provider`},
    updateIdentityProvider: {path: '/edit', pageTitle: 'Edit Identity Provider'},
    kbArticle: {path: 'http://www.storagelink.co/redirects/config-credentials/aws', menuTitle: 'Knowledge Base Article'},
    adminLogin: {path: '/admin'},
    token: { path: '/token' },
    linkExternalAccount: { path: '/linkaccount'}
};

export default routes;
