import React from 'react';
import PageSection from '../common/PageSection';
import ThemeCustomizationForm from './ThemeCustomizationForm';
import HelpSystemContext from '../../context/HelpSystemContext';

export default function ThemeCustomizationComponent() {
    return (
        <HelpSystemContext.Consumer>
            {open => <>
                <PageSection title={'Theme Customizations'}
                             subtitle={'Configure the look and feel of the StorageLink dashboard with your own custom branding.'}
                />
                <ThemeCustomizationForm />
            </>}
        </HelpSystemContext.Consumer>
    );
}

ThemeCustomizationComponent.propTypes = {}
