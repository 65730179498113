// View default values here: https://material-ui.com/customization/default-theme/

const palette = {
    primary: {
        main: '#134c99',
        contrastText: '#fff',
    },
    secondary: {
        main: '#27b2fa',
        contrastText: '#000000',
    },
    error: {
        light: '#FF5F52',
        main: '#C62828',
        dark: '#8E0000',
    },
    warning: {
        light: '#FFF176',
        main: '#FFCB3E',
        dark: '#C89B00',
    },
    info: {
        light: '#B2DFDB',
        main: '#3DD0B7',
        dark: '#009E87',
    },
    success: {
        light: '#6BAC55',
        main: '#3B7C29',
        dark: '#004F00',
    },
    tertiary: {
        light: '#FFF176',
        main: '#FFCB3E',
        dark: '#C89B00',
    },
    background: {
        default: '#fff',
        paper: '#fff',
    },
    text: {
        primary: '#000000',
        secondary: '#546e7a',
    },
    navbar: {
        main: '#fff',
        text: '#949292',
    }
};

export default palette;
