import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import routes from '../../../util/routes';
import {AWS, AZURE, cloudProviderNames, GCP} from '../../../util/cloud_providers';
import AwsCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/AwsCloudConnectionCredentialsForm";
import AzureCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/AzureCloudConnectionCredentialsForm";
import GcpCloudConnectionCredentialsForm from "../../cloudconnectioncredentials/GcpCloudConnectionCredentialsForm";
import useCloudCredential from "../../../api/useCloudCredential";
import CircularProgressButton from "../../common/CircularProgressButton";
import PageLoader from "../../common/PageLoader";
import {isEmpty} from "../../../util/helpers";
import {Prompt} from "react-router";
import toast from "toasted-notes";
import CustomAlert from "../../common/CustomAlert";
import api_routes from "../../../util/api_routes";
import axios from "axios";
import {convertErrorsToObject} from "../../../util/errorHandler";
import HelpSystemContext from '../../../context/HelpSystemContext';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

function CloudCredentialUpdateComponent() {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const [isBlocking, setIsBlocking] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});

    const {credential, isLoading} = useCloudCredential();
    const [editCredential, setEditCredential] = useState(null);

    useEffect(() => {
        if (!isLoading) {
            setEditCredential({...credential})
        }
    }, [credential, isLoading])

    const handleCredentialsChange = (data) => {
        setEditCredential(prevState => {
            let newState = {...prevState, ...data};

            Object.keys(data).forEach(prop => {
                if ((isEmpty(credential[prop]) && isEmpty(data[prop])) || (data[prop] === credential[prop])) {
                    setIsBlocking(false);
                } else {
                    setIsBlocking(true);
                }
            })

            return newState;
        });
    }

    // Update the state from updated props.
    async function handleSubmit(event) {
        event.preventDefault();
        setSaving(true)
        let payload = {...editCredential, "displayName": editCredential.displayName.trim()};
        Object.keys(payload).filter(value => !['id', 'cloudProvider', 'displayName', 'accessKeyId'].includes(value)).forEach(value => {
            if (payload[value] === credential[value]) {
                delete payload[value]
            }
        })
        try {
            await axios.put(
                api_routes.cloudCredentials.endpoint.concat(`/${credential.id}`),
                payload
            );
            toast.notify(({onClose}) =>
                <CustomAlert message={`Updated cloud credential ${credential.displayName}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            setErrors(convertErrorsToObject(error.response));
        } finally {
            setSaving(false)
        }
    }

    function handleCancel() {
        history.push(routes.settings.path);
    }

    return (
        <div className={classes.root}>
            <form
                onSubmit={handleSubmit}
                noValidate
            >

                <HelpSystemContext.Consumer>
                    {open =>
                        <Grid container>
                            <Grid item xs={12} md={open ? 12 : 10} xl={open ? 10 : 8}>
                                {!editCredential && <PageLoader/>}
                                {editCredential && <>
                                    <Typography
                                        variant='h5'>{routes.updateCloudCredentials.pageTitle(cloudProviderNames[credential?.cloudProvider])}</Typography>
                                    {credential.cloudProvider === AWS &&
                                        <AwsCloudConnectionCredentialsForm credentials={editCredential}
                                                                           handleChange={handleCredentialsChange}
                                                                           errors={errors}
                                        />
                                    }
                                    {credential.cloudProvider === AZURE &&
                                        <AzureCloudConnectionCredentialsForm credentials={editCredential}
                                                                             handleChange={handleCredentialsChange}
                                                                             errors={errors}
                                        />
                                    }
                                    {credential.cloudProvider === GCP &&
                                        <GcpCloudConnectionCredentialsForm credentials={editCredential}
                                                                           handleChange={handleCredentialsChange}
                                                                           errors={errors}
                                        />
                                    }
                                </>}
                                <Box display='flex'>

                                    <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                                            label='Save' mt={2}
                                                            inProgress={saving}
                                                            fullWidth={false}
                                    />
                                    <CircularProgressButton label='Cancel' theme={theme} size='small' mt={2}
                                                            onClick={handleCancel} variant='text'
                                    />
                                    <Prompt when={!saving && isBlocking}
                                            message={'Are you sure you would like to cancel? Your changes will not be saved.'}/>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </HelpSystemContext.Consumer>
            </form>
        </div>
    );
}

export default CloudCredentialUpdateComponent;
