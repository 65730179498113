import awsIcon from "@iconify-icons/mdi/aws";
import azureIcon from "@iconify-icons/mdi/microsoft-azure";
import googleIcon from "@iconify-icons/mdi/google-cloud";
import {Icon} from "@iconify/react";
import React from "react";
import {string} from "prop-types";
import {AWS, AZURE, GCP} from "../../util/cloud_providers";

export function CloudProviderIcon(props) {
    const {provider} = props

    function providerIcon(provider) {
        switch (provider) {
            case AWS:
                return awsIcon;
            case AZURE:
                return azureIcon;
            case GCP:
                return googleIcon;
            default:
                return null;
        }
    }

    return <Icon width={22} icon={providerIcon(provider)}/>;
}

CloudProviderIcon.propTypes = {provider: string};

