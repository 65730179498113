import qs from 'querystring';
import axios from 'axios';
import api_routes from '../../util/api_routes';

const {clientid, clientsecret} = window._env_ || {};
const CLIENT_ID = clientid || process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = clientsecret || process.env.REACT_APP_CLIENT_SECRET;

export const KEY_USER_LOGIN_METHOD = 'user-login-method';
export const VALUE_USER_LOGIN_METHOD_SSO = 'sso';
export const VALUE_USER_LOGIN_METHOD_SLINK = 'slink';

export const authenticationService = {
    login,
    loginWithSingleUseToken,
    logout,
    clearTokenInfo,
    setToken: storeToken,
    setName: storeName,
    token: localStorage.getItem('swiftgw'),
    username: localStorage.getItem('swiftgw-user'),
    getToken: () => localStorage.getItem('swiftgw'),
    getUsername: () => localStorage.getItem('swiftgw-user'),
};

async function hitLoginEndpoint(data) {
    let instance = getAxiosInstanceWithoutInterceptors();
    let response = await instance.request({
        url: `${api_routes.auth.endpoint}`,
        method: 'post',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET,
        },
        data: data
    });

    const { access_token: token, username, resetPassword, roles } = response.data;

    if (!resetPassword) {
        await storeToken(token);
        await storeName(username);
    }

    await setBearerAuth(token);

    return { token, username, resetPassword, roles: roles?.map(object => object.authority) };
}

async function login({username, password}) {
    let data = qs.stringify({
        'grant_type': 'password',
        'username': username,
        'password': password
    });

    return await hitLoginEndpoint(data);
}

async function loginWithSingleUseToken({username, password, token}){

    const params = {
        'grant_type': 'urn:ietf:params:oauth:grant-type:single-use-auth',
        'code': token
    };

    if(username && password){
        params.username = username;
        params.password = password;
    }

    const data = qs.stringify(params);

    return await hitLoginEndpoint(data);
}

async function setBearerAuth(token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function storeToken(token){
    localStorage.setItem('swiftgw', token);
    await setBearerAuth(token);
}

async function storeName(name){
    localStorage.setItem('swiftgw-user', name);
}


function clearTokenInfo() {
    localStorage.removeItem('swiftgw');
    localStorage.removeItem('swiftgw-user');
    localStorage.removeItem(KEY_USER_LOGIN_METHOD);
}

function getAxiosInstanceWithoutInterceptors() {
    let instance = axios.create();
    // Clear the response interceptor that loops 401 back to logout
    instance.interceptors.response.handlers = [];
    return instance;
}

async function logout() {
    let target = window.location.origin;
    console.log("Logging out..")
    let instance = getAxiosInstanceWithoutInterceptors();
    let response;
    try {
         response = await instance.request({
            method: 'POST',
            url: api_routes.logout.endpoint,
            params: {
                target
            }
        });
    }
    catch(ignore){
        //Ignore exception and clear tokens
    }
    finally {
        clearTokenInfo();
        delete axios.defaults.headers.common['Authorization'];
    }


    if (response?.data?.idpRedirect) {
        target = response.data.idpRedirect;
    }
    if(target.startsWith(window.location.origin)) {
        window.history.replaceState({}, null, target);
        window.location.reload();
    } else {
        window.location.href = target;
    }

}
