import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { iconStyle } from '../filesystem/FilesystemTableComponent';
import { Role } from '../auth/accessControl/role';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import useUsers from '../../api/useUsers';
import SearchField from '../common/SearchField';

export const userIconDict = {
    'user': <PersonOutlineOutlinedIcon color='primary' sx={iconStyle}/>,
    'admin': <ManageAccountsOutlinedIcon color='primary' sx={iconStyle}/>,
}

const tableRowStyle = (theme) => ({
    '& td': {border: 'none', padding: '.15rem .5rem'},
    '&:last-child td, &:last-child th': {border: 0},
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.action.hover,
    }
});


function UserListComponent() {

    const [page, setPage] = useState(0); // page number
    const KEY_USER_PAGE_SIZE = 'users-page-size';
    const [size, setSize] = useState(parseInt(localStorage.getItem(KEY_USER_PAGE_SIZE)) || 10); // rows per page
    const [sort, setSort] = useState('ASCENDING');
    const [search, setSearch] = useState('');

    const theme = useTheme();
    const history = useHistory();

    const {userPages} = useUsers({
        params: {
            username: search,
            page,
            size
        },
        dependencies: {
            sortOrder: sort
        }
    });

    useEffect(()=>{
        localStorage.setItem(KEY_USER_PAGE_SIZE, size);
    }, [size])

    const sortUsers = function sortUsers() {
        if (sort === 'ASCENDING') {
            setSort('DESCENDING');
        } else {
            setSort('ASCENDING');
        }
    }

    const searchUsers = function searchUsers(text) {
        setPage(0);
        setSearch(text);
    }

    // noinspection MagicNumberJS
    return (
        <Box p={3}>
            <Typography variant='h5'>{routes.users.pageTitle}</Typography>
            <Grid container direction='row' mb={2} mt={2} alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <CircularProgressButton label='Create'
                                            fullWidth={false}
                                            buttonTextTransform='none'
                                            size='small'
                                            style={{borderRadius: '20px'}}
                                            mt={0}
                                            startIcon={<AddOutlinedIcon/>}
                                            onClick={() => history.push(`${routes.users.path}${routes.createUser.path}`)}
                    />
                </Grid>
                <Grid item>
                    <SearchField onSubmit={searchUsers} placeholder='Search users...'/>
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead sx={{
                        '& th': {
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            fontWeight: 800,
                            padding: '.5rem'
                        }
                    }}>
                        <TableRow>
                            <TableCell>Username
                                <IconButton size='small' sx={{ml: 0.5}} onClick={sortUsers}>
                                    {sort === 'ASCENDING' && <ArrowUpwardSharpIcon fontSize='small'/>}
                                    {sort === 'DESCENDING' && <ArrowDownwardSharpIcon fontSize='small'/>}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userPages.content.length !== 0 && userPages.content.map(row => (
                            <TableRow key={row.id}
                                      onClick={() => history.push(`${routes.users.path}/${row.id}/edit`)}
                                      sx={tableRowStyle(theme)}>
                                <TableCell>{row.roles.includes(Role.ADMIN) ? userIconDict['admin'] : userIconDict['user']} {row.username}</TableCell>
                            </TableRow>
                        ))}
                        {userPages.content.length === 0 &&
                            <TableRow key='empty' sx={{'& td': {border: 'none', padding: '.5rem .1rem'}}}>
                                <TableCell colSpan={1} align='center'>No records found</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                {userPages.content.length !== 0 &&
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={userPages.totalElements}
                        rowsPerPage={size}
                        page={userPages.number}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => setSize(parseInt(event.target.value))}
                    />
                }
            </TableContainer>
        </Box>
    );
}

export default UserListComponent;
