import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Publish } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import FormControlInput from '../common/FormControlInput';
import { isEmpty, isUndefined } from '../../util/helpers';


function GcpCloudConnectionCredentialsForm(props) {
    const [displayNameManualChange, setDisplayNameManualChange] = useState(props.credentials.displayName !== "");

    const handleTextChange = (event) => {
        props.handleChange({[event.target.name]: event.target.value});
        setDisplayNameManualChange(event.target.value !== "");
    }

    const originalFilename = useRef(props.credentials.jsonKeyFilename);

    const handleUploadKey = (event) => {
        let inputFile = event?.target.files[0];
        if (!isUndefined(inputFile)){
            let reader = new FileReader();
            reader.onload = function readOnLoad(e) {
                let contents = e.target.result;
                props.handleChange({
                    [event.target.name]: Buffer.from(contents).toString('base64'),
                    jsonKeyFilename: inputFile.name
                });
                if (!displayNameManualChange) {
                    props.handleChange({displayName: `GCP - ${inputFile.name}`});
                }
            };
            reader.readAsBinaryString(inputFile);
        }
        else{
            props.handleChange({
                [event.target.name]: '',
                jsonKeyFilename: ''
            });
            if (!displayNameManualChange) {
                props.handleChange({displayName: ''});
            }
        }
    };

    const fileChanged = !isEmpty(props.credentials.jwtToken)

    return (<>
        <FormControlInput label='Upload Service Account JSON Key File'
                          uncontrolled
                          helperText={props.credentials?.secretSet && !fileChanged ? `Using key file ${originalFilename.current}.`: ''}
                          sx={{
                              'input[type=file]': {
                                  opacity: props.credentials?.secretSet && !fileChanged ? 0.0 : 1.0
                              }
                          }}
                          name='jwtToken'
                          onChange={handleUploadKey}
                          errorMessage={props.errors.jwtToken}
                          type='file'
                          inputProps={{accept: '.json'}}
                          startAdornment={
                              <InputAdornment position='start' onClick={() => document.getElementById('jwtToken').click()}>
                                  <Publish size='small'/> {props.credentials?.secretSet && !fileChanged && originalFilename.current}
                              </InputAdornment>
                          }
                          width='100'
                          helpLink={{
                              href: 'http://www.storagelink.co/redirects/azure-connection-string.html',
                              target: '_blank',
                              text: 'Where do I find the Service Account JSON Key File?'
                          }}        />
        <FormControlInput value={props.credentials.displayName}
                          label='Credential Display Name'
                          name='displayName'
                          helperText='Name used to identify this cloud connection. By default the name is "GCP - {filename of uploaded JSON Key File}".'
                          placeholder='Enter unique name'
                          onChange={handleTextChange}
                          autoComplete='off'
                          errorMessage={props.errors.displayName}
                          width='100'
        />
    </>);
}

GcpCloudConnectionCredentialsForm.propTypes = {
    credentials: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
};

GcpCloudConnectionCredentialsForm.defaultProps = {
    credentials: {},
    errors: {},
    handleChange: () => {
    },
};

export default GcpCloudConnectionCredentialsForm;
