import React, { useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import CustomAlert from './CustomAlert';
import FormControlInput from './FormControlInput';
import CircularProgressButton from './CircularProgressButton';
import api_routes from '../../util/api_routes';


function PasswordField(props) {

    const {password, generatePassword, visibilityToggle} = props;
    const [showPassword, setShowPassword] = useState(false);

    const handleGeneratePassword = async function handleGeneratePassword() {
        try {
            let response = await axios.get(api_routes.generatePassword.endpoint);
            props.onChange({target: {name: props.name, value: response.data}});
        } catch (error) {
            console.error(error);
            toast.notify(({onClose}) =>
                <CustomAlert type='error' message={'Could not generate password!'} onClose={onClose}/>);
        }
    };

    const togglePassword = () => setShowPassword((show) => !show);

    return (
        <Box sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column',}}>
            <FormControlInput {...props} value={password}
                              type={showPassword ? 'text' : 'password'}
                              endAdornment={
                                  <InputAdornment position='end'>
                                      {visibilityToggle &&
                                          <IconButton edge='end' onClick={togglePassword}
                                                      aria-label='toggle password visibility' size='small'>
                                              {showPassword ? <VisibilityOffOutlined fontSize={'small'}/> :
                                                  <VisibilityOutlined fontSize={'small'}/>}
                                          </IconButton>
                                      }
                                  </InputAdornment>
                              }
            />

            {generatePassword &&
                <CircularProgressButton fullWidth={false} buttonTextTransform='none' mt={0} display='inline'
                                        label='Suggest password' variant='text' size='small'
                                        onClick={handleGeneratePassword}/>
            }
        </Box>
    );
}

PasswordField.propTypes = {
    password: PropTypes.string.isRequired,
    setPassword: PropTypes.func,
    onChange: PropTypes.func,
    width: PropTypes.string,
    allowCopy: PropTypes.bool,
    generatePassword: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    tooltip: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    creatingUser: PropTypes.bool,
    required: PropTypes.bool,
    margin: PropTypes.string,
    removeBackground: PropTypes.bool,
    visibilityToggle: PropTypes.bool,
};
PasswordField.defaultProps = {
    creatingUser: false,
    name: 'password',
    placeholder: 'Enter password',
    label: 'Password',
    generatePassword: false,
    allowCopy: false,
    password: '',
    visibilityToggle: true,
};
export default PasswordField;
