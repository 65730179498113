import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';
import PageSection from '../../common/PageSection';
import FormControlInput from '../../common/FormControlInput';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import {isUndefined} from '../../../util/helpers';

function AwsCloudConnectionForm(props) {

    const  {onCloudConnectionChange, basePrefix, encryptionType, encryptionId, region} = props;

    const [cloudConnection, setCloudConnection] = useState({
        basePrefix: basePrefix,
        encryptionType: encryptionType ?? 'SSE_S3',
        encryptionId: encryptionId,
        region: region
    });

    useEffect(() => {
        if (!isUndefined(basePrefix)) {
            setCloudConnection((prevState) => ({
                ...prevState,
                basePrefix: basePrefix,
                encryptionType: encryptionType ?? 'SSE_S3',
                encryptionId: encryptionId,
                region: region
            }));
        }
    }, [basePrefix, encryptionType, encryptionId, region]);

    const handleTextChange = (event) => {
        setCloudConnection((prevState) => ({...prevState, [event.target.name]: event.target.value}));
    }

    useEffect(() => {
        if (onCloudConnectionChange) {
            onCloudConnectionChange(cloudConnection);
        }
    }, [cloudConnection, onCloudConnectionChange]);

    return (
        <>
            <FormControlInput value={cloudConnection.basePrefix} width='100' label='S3 URL' name='basePrefix'
                              onChange={handleTextChange} placeholder='Enter URL for S3 bucket'
                              helperText="Ex: s3://bucket-name/key-prefix"
                              errorMessage={props.errors?.basePrefix} autoComplete='off'
                              allowCopy
            />
            <FormControlInput value={cloudConnection.region} width='100' label='Region' name='region'
                              onChange={handleTextChange} autoComplete='off'
                              placeholder='Enter a bucket region or leave blank for default'
                              helperText="Ex: us-east-1"
                              errorMessage={props.errors?.region}
            />
            <PageSection title='S3 Encryption Type' titleVariant='body1'
                         subtitle='Uploaded objects will be encrypted to this level.'
                         subtitleVariant='body2'
                         pb={0} mt={3}/>
            <FormControlRadioGroup name='encryptionType' value={cloudConnection.encryptionType}
                                   onChange={handleTextChange}
                                   helperText={'New objects uploaded by the users will be encrypted to this level. ' +
                                       'The default and recommended option is SSE-S3.'}
                                   options={[{
                                       value: 'SSE_S3', label: 'SSE-S3'
                                   }, {
                                       value: 'NONE', label: 'No Encryption'
                                   }, {
                                       value: 'SSE_KMS',
                                       label: <Grid container direction='column' mt={1}>
                                           <Grid item><Typography variant='body2'>KMS Key</Typography></Grid>
                                           {cloudConnection.encryptionType === 'SSE_KMS' && <>
                                               <Grid item>
                                                   <FormControlInput value={cloudConnection.encryptionId} width='100'
                                                                     name='encryptionId'
                                                                     placeholder='Enter KMS Key'
                                                                     autoComplete='off'
                                                                     onChange={handleTextChange}
                                                   />
                                               </Grid>
                                           </>
                                           }
                                       </Grid>,
                                       style: {alignItems: 'flex-start'}
                                   }]}
            />
        </>
    )
}

AwsCloudConnectionForm.propTypes = {
    name: PropTypes.string,
    notes: PropTypes.string,
    basePrefix: PropTypes.string,
    region: PropTypes.string,
    encryptionType: PropTypes.string,
    encryptionId: PropTypes.string,
    errors: PropTypes.object,
    onCloudConnectionChange: PropTypes.func,
};

AwsCloudConnectionForm.defaultProps = {
    errors: {},
};

export default AwsCloudConnectionForm;
