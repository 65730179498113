import "typeface-montserrat";
import "typeface-raleway";

const headingCopyFontFamily = 'Raleway, sans-serif';
const bodyCopyFontFamily = 'Montserrat, serif';

const typographyOptions =  {
  fontFamily: bodyCopyFontFamily,
  h1: {
    fontFamily: headingCopyFontFamily
  },
  h2: {
    fontFamily: headingCopyFontFamily
  },
  h3: {
    fontFamily: headingCopyFontFamily
  },
  h4: {
    fontFamily: headingCopyFontFamily
  },
  h5: {
    fontFamily: headingCopyFontFamily
  },
  h6: {
    fontFamily: headingCopyFontFamily
  },
  subtitle1: {
    fontFamily: headingCopyFontFamily
  },
  subtitle2: {
    fontFamily: headingCopyFontFamily
  },
  body1: {
    fontFamily: bodyCopyFontFamily
  },
  body2: {
    fontFamily: bodyCopyFontFamily
  },
  button: {
    fontFamily: headingCopyFontFamily
  },
  caption: {
    fontFamily: headingCopyFontFamily
  },
  overline: {
    fontFamily: headingCopyFontFamily
  }
};

export default typographyOptions;
