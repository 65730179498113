import { useState } from 'react';
import axios from 'axios';
import { isEmpty } from '../util/helpers';
import api_routes from '../util/api_routes';
import httpStatus from '../util/http_status';
import useSWRInfinite from 'swr/infinite'
import FolderError from "../util/FolderError";

const initParams = {
    path: '', // path to the folder of file
};

const initFilesystem = {
    content: [],
};

const PAGE_SIZE= 100;

function useFilesystem({ params = initParams }) {

    const { path, metadataOnly = false } = params;

    const [folderNotFound, setFolderNotFound] = useState(false);
    const getKey = (pageIndex, previousPageData) => {
        // reached the end
        if (previousPageData && !previousPageData.nextCursor) return null

        let url= `${api_routes.filesystem.endpoint}`
        if (!isEmpty(path)) {
            url += `/${path.replace(/^\/+/, '')}`;
        }

        // first page, we don't have `previousPageData`
        if (pageIndex === 0) return [url, metadataOnly]

        return [url, metadataOnly, previousPageData.nextCursor]
    }

    const {data, error, isLoading, isValidating, mutate, size, setSize} = useSWRInfinite(getKey, ([url, metadataOnly, cursor]) => fetchFilesystem(url, metadataOnly, cursor),
      {
          fallbackData: [initFilesystem],
          revalidateOnFocus: !metadataOnly,
          revalidateOnReconnect: !metadataOnly
      });

    async function fetchFilesystem(url, metadataOnly, cursor) {
        try {
            const result = await axios.get(url, {
                params: {metadataOnly, cursor, size: PAGE_SIZE}
            });
            setFolderNotFound(false);
            return result.data
        } catch (error) {
            if (!isEmpty(error) && !isEmpty(error.response) && error.response.status === httpStatus.notFound) {
                setFolderNotFound(true);
            }
            if(!isEmpty(error) && !isEmpty(error.response) && !isEmpty(error.response.data) && error.response.data.folderPath){
                throw new FolderError(error?.response?.data?.message,
                    error?.response?.data?.folderPath);
            } else {
                throw new Error(error?.response?.data?.message);
            }
        }
    }

    const dataIsEmpty = data?.[0]?.content?.length === 0;
    const hasMoreData = !dataIsEmpty && data && data[data.length - 1]?.nextCursor !== null

    return {isLoading, isValidating, refresh: mutate, data, folderNotFound, error, numPages: size, setNumPages: setSize, hasMore: hasMoreData};
}

export default useFilesystem;
