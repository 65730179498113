import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import 'toasted-notes/src/styles.css';
import './util/node_shims';

import routes from './util/routes';
import { baseTheme, customThemePromise } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Authenticator from './container/auth/Authenticator';
import UserCreateComponent from './component/user/UserCreateComponent';
import FilesystemListContainer from './container/filesystem/FilesystemListContainer';
import IdentityProviderUpdateComponent from './component/settings/identityprovider/IdentityProviderUpdateComponent';
import IdentityProviderCreateComponent from './component/settings/identityprovider/IdentityProviderCreateComponent';
import { Role } from './component/auth/accessControl/role';
import PrivateRoute from './component/auth/accessControl/PrivateRoute';
import FooterComponent from './component/FooterComponent';
import ResponsiveDrawer from './component/layout/ResponsiveDrawer';
import SystemInfoComponent from './component/SystemInfoComponent';
import LinkExternalAccount from './container/auth/LinkExternalAccount';
import ChangePasswordComponent from './component/user/ChangePasswordComponent';
import SettingsComponent from './component/settings/SettingsComponent';
import UserUpdateComponent from './component/user/UserUpdateComponent';
import UserListComponent from './component/user/UserListComponent';
import CloudCredentialCreateComponent from './component/settings/cloudcredential/CloudCredentialCreateComponent';
import CloudCredentialUpdateComponent from './component/settings/cloudcredential/CloudCredentialUpdateComponent';
import FsFolderUpdateComponent from './component/filesystem/FsFolderUpdateComponent';
import FsFolderCreateComponent from './component/filesystem/FsFolderCreateComponent';
import SessionProfile from "./util/SessionProfile";

const initFeatures = () => {
    localStorage.setItem('flags', JSON.stringify([
        { name: 'azure', description: 'Displays the Azure UI features', active: true },
        { name: 'gcp', description: 'Displays the Google UI features', active: true },
    ]));
}


function App() {
    initFeatures();

    function initSessionProfile() {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('hideTopBar')) {
            SessionProfile.setShowTopBar(!JSON.parse(queryParams.get('hideTopBar')));
        }
    }

    initSessionProfile();

    const [theme, setTheme] = useState(baseTheme);
    const [themeLoaded, setThemeLoaded] = useState(false);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        customThemePromise.then((theme) => {
            setTheme(theme);
            const links = [
                { rel: "manifest", href: theme?.clientBranding?.manifest }
            ]
            links.push(...theme?.clientBranding?.favIcons);
            setLinks(links);
            setThemeLoaded(true);
        }).catch(reason => console.error("Custom Theme promise failed", reason));
    }, []);

    if (themeLoaded) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <HelmetProvider>
                        <Helmet link={links}>
                            <title>
                                {theme.clientBranding.pageTitle}
                            </title>
                        </Helmet>
                        <CssBaseline/>
                        <Router>
                            <Switch>
                                <Route exact path={`${routes.linkExternalAccount.path}`}>
                                    <LinkExternalAccount/>
                                </Route>
                                <Route>
                                    <Authenticator>
                                        <ResponsiveDrawer>
                                            <SystemInfoComponent/>
                                            <div className={'content'}>
                                                <Switch>
                                                    <PrivateRoute exact path={routes.users.path}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <UserListComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact
                                                                  path={`${routes.users.path}${routes.createUser.path}`}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <UserCreateComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact
                                                                  path={`${routes.users.path}/:userId${routes.updateUser.path}`}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <UserUpdateComponent/>
                                                    </PrivateRoute>
                                                    <Route exact path={`${routes.profile.path}`}>
                                                        <ChangePasswordComponent/>
                                                    </Route>
                                                    <PrivateRoute exact path={`${routes.settings.path}`}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <SettingsComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact
                                                                  path={`${routes.settings.path}${routes.cloudCredentials.path}${routes.createCloudCredentials.path}`}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <CloudCredentialCreateComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact
                                                                  path={`${routes.settings.path}${routes.cloudCredentials.path}/:cloudCredentialId${routes.updateCloudCredentials.path}`}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <CloudCredentialUpdateComponent/>
                                                    </PrivateRoute>

                                                    <PrivateRoute path={routes.filesystemCreateFolder.path}
                                                                  allowedRoles={[Role.USER, Role.ADMIN]}>
                                                        <FsFolderCreateComponent/>
                                                    </PrivateRoute>

                                                    <PrivateRoute path={routes.filesystemUpdateFolder.path}
                                                                  allowedRoles={[Role.ADMIN]}>
                                                        <FsFolderUpdateComponent/>
                                                    </PrivateRoute>

                                                    <PrivateRoute path={`${routes.filesystem.path}`}
                                                                  allowedRoles={[Role.USER, Role.ADMIN]}>
                                                        <FilesystemListContainer/>
                                                    </PrivateRoute>

                                                    <PrivateRoute
                                                        path={`${routes.settings.path}${routes.identityProviders.path}${routes.createIdentityProvider.path}`}
                                                        allowedRoles={[Role.ADMIN]}>
                                                        <IdentityProviderCreateComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute
                                                        path={`${routes.settings.path}${routes.identityProviders.path}/:identityProviderId${routes.updateIdentityProvider.path}`}
                                                        allowedRoles={[Role.ADMIN]}>
                                                        <IdentityProviderUpdateComponent/>
                                                    </PrivateRoute>
                                                    <PrivateRoute
                                                        path={``}
                                                        allowedRoles={[Role.ADMIN]}>
                                                        <Redirect to={routes.homeAdmin.path}/>
                                                    </PrivateRoute>
                                                    <PrivateRoute
                                                        path={``}
                                                        allowedRoles={[Role.USER]}>
                                                        <Redirect to={routes.homeSwift.path}/>
                                                    </PrivateRoute>
                                                </Switch>
                                            </div>
                                            <div className='footer'>
                                                <FooterComponent/>
                                            </div>
                                        </ResponsiveDrawer>
                                    </Authenticator>
                                </Route>
                            </Switch>
                        </Router>
                    </HelmetProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    return <></>;
}

export default App;
