import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import useUser from '../../api/useUser';
import CustomAlert from '../common/CustomAlert';
import HelpSystemContext from '../../context/HelpSystemContext';
import UserForm from './UserForm';
import { Role } from '../auth/accessControl/role';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import { isUndefined, stripStartingAndTrailingSlashes } from '../../util/helpers';
import { convertErrorsToObject } from '../../util/errorHandler';


function UserUpdateComponent() {

    const history = useHistory();
    const {userId} = useParams();
    const {
        isLoading: loadingUser,
        user: fetchedUser,
        updateUser,
        deleteUser,
    } = useUser({params: {userId}});
    const [user, setUser] = useState(fetchedUser);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // This masks a bug regarding how canceling and then re-editing a user will set the folder to temporarily think the user's home folder is root
        if (!loadingUser && !isUndefined(fetchedUser)) {
            setUser({...fetchedUser});
        }
    }, [fetchedUser, loadingUser]);

    useEffect(() => {
        if (user.role === Role.ADMIN) {
            setUser(prevState => ({...prevState, homeFolderPath: `/`}));
        } else {
            setUser(prevState => ({...prevState, homeFolderPath: fetchedUser.homeFolderPath}))
        }
    }, [user.role, fetchedUser.homeFolderPath])

    const handleSubmit = async function handleUpdateUser(event) {
        event.preventDefault();
        try {
            await updateUser(user);
            toast.notify(({onClose}) => <CustomAlert message={`Successfully updated ${user.username}!`}
                                                     onClose={onClose}/>);
            setErrors({});
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
            throw error
        }
    };

    const handleChange = function handleChangeOnUpdateUserForm(event) {
        if(event.target) {
            setUser({ ...user, [event.target.name]: event.target.value });
        }
    }

    const handleCheckboxChange = function handleCheckboxChangeOnUpdateUserForm(name, event) {
        setUser({...user, [name]: event.target.checked});
    }

    const handleFolderPermissionChange = function handleFolderPermissionChange(name, event) {
        setUser({...user, homeFolderPermissions: {...user.homeFolderPermissions, [name]: event.target.checked}});
    }

    const handleCancel = function handleCancel() {
        history.push(routes.users.path);
    }

    const handleDelete = async function handleUserDelete() {
        try {
            await deleteUser();
            toast.notify(({onClose}) =>
                <CustomAlert message={`Deleted user ${user.username}.`} onClose={onClose}/>);
            history.push(routes.users.path);
        } catch (error) {
            if (error.response.status === httpStatus.methodNotAllowed) {
                toast.notify(({onClose}) => <CustomAlert type='error'
                                                         message={'Cannot delete the last enabled admin.'}
                                                         onClose={onClose}/>);
            }
            console.error(error);
        }
    }

    const saveFolder = async function updateFolder({cloudConnectionId, homeFolderPath}) {
        let filesystem_api = `${api_routes.filesystem.endpoint}/${stripStartingAndTrailingSlashes(homeFolderPath)}`;
        return await axios.put(filesystem_api,
            {connectionId: cloudConnectionId},
            {
                headers: {'Content-Type': 'application/json'}
            }
        );
    };

    return (
        <Box p={3}>
            <Typography variant='h5'>{routes.updateUser.pageTitle}</Typography>
            <HelpSystemContext.Consumer>
                {open =>
                    <Grid container>
                        <Grid item xs={12} md={open ? 12 : 8} xl={8}>
                            {!loadingUser &&
                                <UserForm handleSubmit={handleSubmit}
                                          handleChange={handleChange}
                                          handleCheckboxChange={handleCheckboxChange}
                                          handleFolderPermissionChange={handleFolderPermissionChange}
                                          handleCancel={handleCancel}
                                          handleDelete={handleDelete}
                                          user={user}
                                          initialUser={fetchedUser}
                                          errors={errors}
                                          saveFolder={saveFolder}
                                          homeFolderManualChange={true}
                                />
                            }
                        </Grid>
                    </Grid>
                }
            </HelpSystemContext.Consumer>
        </Box>
    );
}

export default UserUpdateComponent;
