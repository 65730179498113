import axios from 'axios';
import palette from './palette';
import typography from './typography';
import components from './components'
import api_routes from '../util/api_routes';
import createMyTheme from './createMyTheme'

const getThemeFromWindowEnvironment = () => {
    const {theme} = window['_env_'] || {};
    return theme;
};

const getCustomTheme =
    new Promise((resolve) => {
        // Import custom theme from the custom folder in the theme directory
        const themeName = getThemeFromWindowEnvironment();
        if (themeName) {
            import(`./custom/${themeName}`).then(themeOverrides => {
                console.log(themeOverrides);
                resolve({...themeOverrides?.themeOptions});
            }).catch(() => {
                console.log(`Theme folder (${themeName}) not found.`);
                resolve({});
            });
        } else {
            return resolve({});
        }
    }).then(function (customTheme) {
        // Fetch theme overrides from the database
        return new Promise((resolve) => {
            axios.get(api_routes.theme.endpoint).then(response => {
                resolve({
                    ...customTheme,
                    palette: {
                        ...customTheme.palette,
                        primary: {main: response.data.primaryThemeColor},
                        secondary: {main: response.data.secondaryThemeColor},
                        text: {
                            primary: response.data.primaryFontColor,
                            secondary: response.data.secondaryFontColor
                        },
                    },
                    clientBranding: {
                        ...customTheme.clientBranding,
                        pageTitle: response.data.title,
                        ...(response.data.logo !== null ? { logo: { navbarBrandImage: `data:image/jpeg;base64,${response.data.logo}` } } : {}),
                        ...(response.data.favicon !== null ? { favIcons: [{rel: "icon", type: "image/png", sizes: "32x32", href: `data:image/jpeg;base64,${response.data.favicon}`}] } : {}),
                    },
                });
            }).catch(() => resolve(customTheme));
        })
    })

export const customThemePromise = getCustomTheme.then(themeOverrides => {
    return createMyTheme({
            ...themeOverrides,
            palette: {...palette, ...themeOverrides?.palette},
            typography: {...typography, ...themeOverrides?.typography},
            components: {...components, ...themeOverrides?.components},
            zIndex: {appBar: 900, drawer: 899}
        }
    );
});

export const baseTheme = createMyTheme({
    palette,
    typography,
    components,
    zIndex: {
        appBar: 900,
        drawer: 899
    },
});

const module = {
    baseTheme,
    customThemePromise
};
export default module;

