import httpStatus from './http_status';
import {isEmpty} from './helpers';

// Convert the array of errors from the api response to an object.
// Array of errors always has the following structure: errors: [{"field": "<field_name>", "error": "<error_text>"}]
function convertErrorsToObject(response) {
    let error = {};
    if (response && response.status === httpStatus.badRequest) {
        if (!isEmpty(response.data) && !isEmpty(response.data.errors)){
            error = response.data.errors.reduce((obj, item) => {
                if (item.field.startsWith("allowedIps[]") || item.field.startsWith("publicKeys[]")) {
                    let [field] = item.field.split('.')
                    if (obj[field] === undefined) {
                        obj[field] = `${item.error}\n`
                    } else {
                        obj[field] += `${item.error}\n`
                    }
                } else {
                    obj[item.field] = item.error;
                }
                return obj;
            }, {});
        }
    }
    return error;
}

function handleErrors(response) {
    let error;
    if (isEmpty(response)) {
        error = { msg: 'Unknown error! Please try again or contact support.'}
    } else if (response.status === httpStatus.unAuthorized) {
        error = {msg: 'Please log in!'};
    } else if (response.status === httpStatus.internalServerError) {
        error = {msg: 'Internal Server Error!'};
    } else {
        error = convertErrorsToObject(response);
    }
    return error;
}

export { convertErrorsToObject, handleErrors };
