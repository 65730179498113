const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VERSION = "1.0.0";

const api_routes = {
  auth: {endpoint: `${BASE_URL}/login`},
  ping: {endpoint: `${BASE_URL}/`},
  logout: {endpoint: `${BASE_URL}/logout`},
  user: {endpoint: `${BASE_URL}/${VERSION}/users`},
  userSshKey: {endpoint: '/sshkeys'},
  allowedips: {endpoint: '/allowedips'},
  userPublicKey: {endpoint: '/sshkeys'},
  userAllowedIps: {endpoint: '/allowedips'},
  firstUserConfiguration: {endpoint: `${BASE_URL}/${VERSION}/admin/config`},
  generateKey: {endpoint: `${BASE_URL}/keypair`},
  firstConnectionTest: {endpoint: `${BASE_URL}/admin/config/test`},
  cloudConnectionTest: {endpoint: `${BASE_URL}/${VERSION}/cloudconnections/test`},
  cloudConnection: {endpoint: `${BASE_URL}/${VERSION}/cloudconnections`},
  cloudCredentials: {endpoint: `${BASE_URL}/${VERSION}/cloudcredentials`},
  cloudConnectionEncryption: {endpoint: 'encryptionOptions'},
  kmsKeys: {endpoint: `${BASE_URL}/kmsKeys`},
  filesystem: {endpoint: `${BASE_URL}/${VERSION}/filesystem`},
  generatePassword: {endpoint: `${BASE_URL}/password`},
  backup: {endpoint: `${BASE_URL}/${VERSION}/backup`},
  identityProvider: {endpoint: `${BASE_URL}/${VERSION}/identityproviders`},
  bannerText: {endpoint: `${BASE_URL}/${VERSION}/bannertext`},
  configuredIdps: {endpoint: `${BASE_URL}/idps`},
  theme: {endpoint: `${BASE_URL}/theme`},
};

export default api_routes;
