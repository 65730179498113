import React, { useState } from 'react';
import {Grid, Menu} from '@mui/material';
import Divider from '@mui/material/Divider';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    StyledChangePasswordLink,
    StyledListItemIcon,
    StyledMenuButton,
    StyledMenuGridItem,
    StyledMenuItem
} from './ResponsiveDrawer';
import routes from '../../util/routes';
import {KEY_USER_LOGIN_METHOD, VALUE_USER_LOGIN_METHOD_SLINK} from '../../container/auth/authenticationService';

function DesktopTopBarNavigation(props) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = function expandNavigationMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = function closeNavigationMenu() {
        setAnchorEl(null);
    };

    return (
        <StyledMenuGridItem item>
            <Grid container justifyContent='flex-end' alignItems={'center'}>
                <Grid item>
                    {props.helpTextIconButton}
                </Grid>
                <Divider orientation='vertical' variant='middle' flexItem/>
                <Grid item>
                    <StyledMenuButton theme={props.theme} sx={{ml: 1}}
                                      aria-controls='user-menu'
                                      aria-haspopup='true'
                                      onClick={handleClick}
                                      endIcon={<ArrowDropDownIcon/>}>
                        Hi, {props.currentUser.username}
                    </StyledMenuButton>
                    <Menu id='user-menu' anchorEl={anchorEl} keepMounted
                          open={Boolean(anchorEl)} onClose={handleClose}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                          }}
                    >
                        {localStorage.getItem(KEY_USER_LOGIN_METHOD) === VALUE_USER_LOGIN_METHOD_SLINK &&
                            <StyledChangePasswordLink theme={props.theme} to={routes.profile.path}>
                                <StyledMenuItem onClick={handleClose}>
                                    <StyledListItemIcon>
                                        <VpnKeyIcon fontSize='small'/></StyledListItemIcon>
                                    {routes.profile.menuTitle}
                                </StyledMenuItem>
                            </StyledChangePasswordLink>
                        }
                        <StyledMenuItem onClick={props.handleSignOut}>
                            <StyledListItemIcon style={{minWidth: '32px'}}>
                                <ExitToAppIcon fontSize='small'/></StyledListItemIcon>
                            Log Out
                        </StyledMenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </StyledMenuGridItem>
    );
}

export default DesktopTopBarNavigation;
