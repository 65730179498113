import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormControlInput from '../common/FormControlInput';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import { isEmpty } from '../../util/helpers';
import api_routes from '../../util/api_routes';
import PasswordField from '../common/PasswordField';
import { UserType } from './accessControl/role';
import {
    KEY_USER_LOGIN_METHOD,
    VALUE_USER_LOGIN_METHOD_SLINK,
    VALUE_USER_LOGIN_METHOD_SSO
} from '../../container/auth/authenticationService';
import handleLoginError from '../../container/auth/LoginErrorHandling';


export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 300px;
`;

export const StyledFormLogo = styled.div`
`;

export const StyledFormContent = styled.div`
  display: flex;
  width: 100%;
  color: rgb(148, 146, 146);
  margin: 24px 0 8px;

  &:before {
    content: "";
    flex: 1 1 0;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0 22px 8px 0;
  }

  &:after {
    content: "";
    flex: 1 1 0;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0 0 8px 22px;
  }
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default function Login(props) {

    const stateParam = {
        "sftpgwRedirectUrl": encodeURIComponent(window.location.origin + routes.linkExternalAccount.path)
    }

    const [isLoading, setIsLoading] = useState(false);
    const [loginForm, setLoginForm] = useState({
        username: '',
        password: '',
    });

    const locationPathname = window.location.pathname;

    const [idps, setIdps] = useState({});
    const [idpGridSize, setIdpGridSize] = useState(4);
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const theme = useTheme();

    const handleChange = function updateLoginFormFields(event) {
        setLoginForm({...loginForm, [event.target.name]: event.target.value})
    };

    const handleSignin = async function signInToStorageLinkWebApp(event) {
        event.preventDefault();
        localStorage.setItem(KEY_USER_LOGIN_METHOD, VALUE_USER_LOGIN_METHOD_SLINK);
        if (isEmpty(loginForm.username)) {
            setUsernameError('Username must not be blank')
            return
        } else {
            setUsernameError('')
        }
        if (isEmpty(loginForm.password)) {
            setPasswordError('Password must not be blank')
            return
        } else {
            setPasswordError('')
        }
        setIsLoading(true);
        try {
            await props.login(loginForm.username.trim(), loginForm.password);
        } catch (error) {
            handleLoginError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOidcSignin = function signInToStorageLinkOidc(registrationId) {
        localStorage.setItem(KEY_USER_LOGIN_METHOD, VALUE_USER_LOGIN_METHOD_SSO);
        window.location.href = `${BASE_URL}/oauth2/authorization/${registrationId}?state=${window.btoa(JSON.stringify(stateParam))}`;
    }

    function sortObj(obj) {
        return Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
        }, {});
    }

    useEffect(() => {
        async function fetchData() {
            try {
                let idpType = UserType.ROLE_SWIFT;
                if (locationPathname === routes.adminLogin.path) {
                    idpType = UserType.ROLE_ADMIN;
                }
                let response = await axios.get(`${api_routes.configuredIdps.endpoint}?type=${idpType}`);
                setIdps(response.data);
                if (Object.entries(response.data).length === 1) {
                    setIdpGridSize(12);
                } else if (Object.entries(response.data).length % 2 === 0) {
                    setIdpGridSize(6);
                } else {
                    setIdpGridSize(4);
                }
            } catch (e) {
                console.log('E', e);
            }
        }

        fetchData();
    }, [locationPathname]);

    return (
        <Grid container justifyContent='center' alignItems='center' className='login-form'>
            <StyledFormWrapper>
                <StyledFormLogo>
                    <StyledLogo src={theme.clientBranding.logo.navbarBrandImage}
                                alt={theme.clientBranding.logo.alt}/>
                </StyledFormLogo>
                <StyledFormContent>
                    Sign In
                </StyledFormContent>
                <Box sx={{width: '100%'}}>
                    <form onSubmit={handleSignin}>
                        <FormControlInput width='100' name='username' value={loginForm.username}
                                          label='Username' onChange={handleChange} errorMessage={usernameError}/>
                        <PasswordField width={'100'} password={loginForm.password}
                                       onChange={handleChange} placeholder='' errorMessage={passwordError}/>
                        <CircularProgressButton label='Sign In' variant='contained' size='medium' mt={2}
                                                type='submit' inProgress={isLoading}/>
                    </form>
                </Box>
                {Object.entries(idps).length > 0 &&
                    <>
                        <StyledFormContent className='form-content'>
                            or Sign In with
                        </StyledFormContent>
                        <Grid item container direction='row' justifyContent='center' alignItems='center' spacing={1}
                              sx={{mt: 1}}>
                            {Object.entries(sortObj(idps)).map(([registrationId, displayName]) =>
                                <Grid item xs={idpGridSize} key={registrationId}>
                                    <CircularProgressButton fullWidth label={displayName}
                                                            variant="outlined" buttonTextTransform={'none'}
                                                            onClick={() => handleOidcSignin(registrationId)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
            </StyledFormWrapper>
        </Grid>
    )
}

Login.prototype = {
    login: PropTypes.func,
};
