import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { DndProvider } from 'react-dnd';
import { useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomAlert from '../common/CustomAlert';
import ConfirmDialog from '../common/ConfirmDialog';
import { fileService } from '../../container/filesystem/fileService';
import ProgressCard from './ProgressCard';
import FilesystemBreadcrumbs from './Breadcrumbs';
import ActionButtonsComponent from './ActionButtonsComponent';
import FilesystemTableComponent from './FilesystemTableComponent';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import { isEmpty, isEmptyArray, isEmptyObject } from '../../util/helpers';
import { Role } from '../auth/accessControl/role';
import { hasAccess } from '../auth/accessControl/AccessControl';
import _ from "lodash";

function FilesystemListComponent(props) {

    const initAvailableActions = useMemo(() => ({
        download: false,
        upload: false,
        createFolder: false,
        editFolder: false,
        delete: false,
        openFolder: false
    }), [])

    const history = useHistory();
    const params = useParams();

    const [selection, setSelection] = useState([]);

    const getPathFromParams = useCallback(() => (isEmpty(params[0]) ? [] : params[0].split('/')),
        [params]);

    const path = getPathFromParams();
    const [availableActions, setAvailableActions] = useState(initAvailableActions);
    const [uploading, setUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [doneUploading, setDoneUploading] = useState(false);
    const [doneDeleting, setDoneDeleting] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [filesUploadProgress, setFilesUploadProgress] = useState({});
    const [filesForUpload, setFilesForUpload] = useState([]);
    const [filesForDelete, setFilesForDelete] = useState([]);
    const {filesystem, triggerRefresh} = props;
    const [currPerms, setCurrentFolderPerms] = useState({});
    const [uploadingMessage, setUploadingMessage] = useState('');

    const currentUser = useSelector(state => state.currentUser);

    history.listen(() => setSelection([]));

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (uploading) {
                // Cancel the event (modern browsers)
                event.preventDefault();
                // Chrome requires returnValue to be set
                event.returnValue = '';
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Clean up the event listener when the component is unmounted
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [uploading, history])

    useEffect(() => {
        if (filesystem && filesystem.length) {
            const currentFolder = filesystem.find(e => e.isCurrentFolder)
            if (currentFolder) {
                setCurrentFolderPerms(currentFolder.currentPermissions);
            }
        } else {
            setCurrentFolderPerms(null)
        }
    }, [props.loadingFilesystem, props.refreshingFilesystem, filesystem, params]);

    let notFound = props.notFound;
    useEffect(() => {
        if (!isEmptyArray(selection)) {
            setAvailableActions({
                download: selection.every((row) => (row.type.toLowerCase() === 'regular' && row.currentPermissions.downloadable)),
                upload: currPerms.uploadable,
                createFolder: currPerms.uploadable,
                editFolder: selection.length === 1 && selection[0].type.toLowerCase() === 'directory',
                openFolder: selection.length === 1 && selection[0].type.toLowerCase() === 'directory' &&
                    !selection[0].isParentFolder && !selection[0].isCurrentFolder,
                delete: selection.every((row) => ((row.type.toLowerCase() === 'regular' || row.type.toLowerCase() === 'directory') &&
                    row.currentPermissions.deletable && !row.isParentFolder && !row.isCurrentFolder)),
            });
        } else if (notFound) {
            setAvailableActions({
                download: false,
                upload: false,
                createFolder: false,
                editFolder: false,
                openFolder: false,
                delete: false,
            });
        } else {
            if (currPerms && currPerms !== {}) {
                setAvailableActions({
                    download: false,
                    upload: currPerms.uploadable,
                    createFolder: currPerms.uploadable,
                    editFolder: false,
                    openFolder: false,
                    delete: false,
                });
            } else {
                setAvailableActions(initAvailableActions);
            }
        }
    }, [currPerms, selection, initAvailableActions, notFound]);

    const {errorMessage, errorPath, isValidating} = props;
    useEffect(() => {
        const path = errorPath ?? getPathFromParams()?.join('/');
        if (!isValidating && errorMessage) {
            if (hasAccess(currentUser.roles, [Role.ADMIN])) {
                history.push(`${routes.filesystemfuncUpdateFolder.path(path)}&redirect=${routes.filesystemfunc.path(getPathFromParams()?.join('/'))}`);
                toast.notify(({onClose}) => <CustomAlert type='error'
                                                         message={errorMessage}
                                                         onClose={onClose}/>,
                    {duration: null});
            } else {
                toast.notify(({onClose}) => <CustomAlert type='error'
                                                         message={"There is an error while loading the folder. Please contact the administrator."}
                                                         onClose={onClose}/>,
                    {duration: null});
            }
        } else if (!isValidating && !errorMessage) {
            toast.closeAll();
        }
    }, [isValidating, errorMessage, history, params, errorPath, getPathFromParams, currentUser.roles])

    function openSelectedFolder() {
        if (!isEmptyArray(selection) && selection.length === 1) {
            changeFolder(selection[0]);
        } else {
            console.error("Open selected folder was called with selected rows !== 1.");
        }
    }

    function changeFolder(row) {
        if (row && row.type.toLowerCase() === 'directory') {
            let path = row.absolutePath.replace(/^[/]/, '');
            history.push(routes.filesystemfunc.path(path));
        } else {
            console.error('Attempted to change folder to a non-folder.');
        }
        setSelection([]);
    }

    function editSelectedFolder() {
        if (!isEmptyArray(selection) && selection.length === 1) {
            if (selection[0] && selection[0].type.toLowerCase() === 'directory') {
                let path = selection[0].absolutePath.replace(/^[/]/, '');
                history.push(routes.filesystemfuncUpdateFolder.path(path));
            } else {
                console.error('Attempted to edit a non-folder.');
            }
        } else {
            console.error("Edit selected folder was called with selected rows !== 1.");
        }
    }

    const getFullPathFromParts = useCallback(() => {
        let filesystem_api = `${api_routes.filesystem.endpoint}`;
        if (path) {
            let temp = [...path];
            if (!isEmpty(temp)) {
                filesystem_api += `/${temp.join('/')}`;
            }
        }
        return filesystem_api;
    }, [path]);

    const handleFileInputChange = useCallback((event) => {
        event.preventDefault();
        let newFileList = [];
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!isEmpty(file.webkitRelativePath)) {
                newFileList.push({file, path: file.webkitRelativePath});
            } else {
                newFileList.push({file, path: file.name});
            }
        }
        setFilesForUpload(newFileList);
    }, []);

    const traverseDirectory = useCallback((entry, fileList = [], path = '') => {
        function readEntriesAsync(reader) {
            return new Promise((resolve, reject) => {
                reader.readEntries(entries => {
                    resolve(entries);
                }, error => reject(error));
            })
        }

        async function enumerateDirectoryWithManyFiles(reader) {
            let resultEntries = [];

            let read = async function () {
                let entries = await readEntriesAsync(reader);
                if (entries.length > 0) {
                    resultEntries = resultEntries.concat(entries);
                    await read();
                }
            }

            await read();
            return resultEntries;
        }

        return new Promise((resolve, reject) => {
            if (entry.isFile) {
                entry.file((file) => {
                    if (!(entry.name).startsWith(".")) {
                        fileList.push(
                            {
                                file,
                                path: path + entry.name,
                            }
                        );
                    }
                    resolve(fileList);
                }, reject);
            } else if (entry.isDirectory) {
                const directoryReader = entry.createReader();
                return enumerateDirectoryWithManyFiles(directoryReader).then(entries => {
                        const promises = [];
                        for (let i = 0; i < entries.length; i++) {
                            const childEntry = entries[i];
                            const childPath = `${path}${entry.name}/`;

                            promises.push(traverseDirectory(childEntry, fileList, childPath));
                        }
                        Promise.all(promises)
                            .then(() => resolve(fileList))
                            .catch(reject);
                    }
                ).catch(reject);
            }
        });
    }, []);

    const handleFileDrop = useCallback(async (items) => {
        const files = [];
        const promises = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type === '') {
                const entry = item.webkitGetAsEntry();

                if (entry) {
                    promises.push(traverseDirectory(entry, files, ''));
                }
            } else {
                const file = item.getAsFile();
                if (file) {
                    files.push({file, path: file.name});
                }
            }
        }
        if (promises) {
            return Promise.allSettled(promises).then(() => setFilesForUpload(files));
        } else {
            setFilesForUpload(files);
        }

    }, [traverseDirectory]);

    const setFiles = useCallback(async function setFiles(event, uploadedFiles = null) {
        if (!isEmpty(event)) {
            handleFileInputChange(event);
        } else {
            await handleFileDrop(uploadedFiles);
        }
    }, [handleFileDrop, handleFileInputChange]);

    /**
     * Performs a list of callable actions (promise factories) so
     * that only a limited number of promises are pending at any
     * given time.
     *
     * @param listOfCallableActions An array of callable functions,
     *     which should return promises.
     * @param limit The maximum number of promises to have pending
     *     at once.
     * @returns A Promise that resolves to the full list of values
     *     when everything is done.
     */
    function throttleActions(listOfCallableActions, limit) {
        // We'll need to store which is the next promise in the list.
        let i = 0;
        let resultArray = new Array(listOfCallableActions.length);

        // Now define what happens when any of the actions completes.
        // Javascript is (mostly) single-threaded, so only one
        // completion handler will call at a given time. Because we
        // return doNextAction, the Promise chain continues as long as
        // there's an action left in the list.
        function doNextAction() {
            if (i < listOfCallableActions.length) {
                // Save the current value of i, so we can put the result
                // in the right place
                let actionIndex = i++;
                let nextAction = listOfCallableActions[actionIndex];
                return Promise.resolve(nextAction())
                    .then(result => {
                        // Save results to the correct array index.
                        resultArray[actionIndex] = {...result, status: 'resolved'};
                    })
                    .catch(result => {
                        resultArray[actionIndex] = {...result, status: 'rejected'};
                    })
                    .then(doNextAction);
            }
        }

        // Now start up the original <limit> number of promises.
        // i advances in calls to doNextAction.
        let listOfPromises = [];
        while (i < limit && i < listOfCallableActions.length) {
            listOfPromises.push(doNextAction());
        }
        return Promise.allSettled(listOfPromises).then(() => resultArray);
    }

    const analyzeUploadProgress = useCallback( () => {
        const entries = Object.entries(filesUploadProgress)
        const items = (entries.length > 1) ? 'items' : 'item';
        const hundredPercentCount = entries.filter(value => value[1].percentage === 100 ).length;
        const completedCount = entries.filter(value => value[1].status === "complete" ).length;
        const failedCount = entries.filter(value => Object.hasOwn(value[1], 'status') && value[1].status !== "complete" ).length;
        return {
            entries,
            items,
            hundredPercentCount,
            completedCount,
            failedCount,
            totalCount: entries.length,
            isDone: completedCount + failedCount === entries.length
        }
    }, [filesUploadProgress]);

    useEffect(()=>{
          const { completedCount, failedCount, totalCount, hundredPercentCount, items } = analyzeUploadProgress();
          if(completedCount === totalCount) {
              setUploadingMessage('Upload succeeded');
          } else if(failedCount === totalCount) {
              setUploadingMessage('Upload failed');
              toast.notify(({onClose}) =>
                <CustomAlert type='error'
                             message={`${failedCount} file${failedCount === 1 ? "" : "s"} failed to upload`}
                             onClose={onClose}/>);
          } else if (failedCount > 0 && completedCount > 0 && completedCount + failedCount === totalCount){
              setUploadingMessage('Upload partially succeeded');
              toast.notify(({onClose}) =>
                  <CustomAlert type='error'
                               message={`${completedCount} file${completedCount === 1 ? "" : "s"} uploaded; ${failedCount} file${failedCount === 1 ? "" : "s"} failed to upload`}
                               onClose={onClose}/>,
                {duration: null});
          }
          else if(hundredPercentCount > 0) {
              setUploadingMessage(`Uploaded ${hundredPercentCount} of ${totalCount} ${items}`);
          } else {
              setUploadingMessage(`Starting upload of ${totalCount} ${items}`);
          }

      },
      [filesUploadProgress, analyzeUploadProgress]
    )

    const filesystem_api = useMemo(() => getFullPathFromParts(), [getFullPathFromParts]);
    useEffect(() => {
        if (filesForUpload.length !== 0) {
            if(!uploading){
                setFilesUploadProgress({});
            }
            setUploading(true);
            setFilesForUpload([]);

            async function uploadFiles() {
                let _filesUploadProgress = {};
                for (let i = 0; i < filesForUpload.length; i++) {
                    _filesUploadProgress[filesForUpload[i].path] = {percentage: 0, name: filesForUpload[i].path};
                }
                setFilesUploadProgress(prevState=> ({...prevState, ..._filesUploadProgress}));

                let _promises = [];

                for (let i = 0; i < filesForUpload.length; i++) {
                    const {file, path} = filesForUpload[i];
                    _promises.push(() => fileService.upload(filesystem_api, file, path, (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setFilesUploadProgress(prevState => ({
                                ...prevState,
                                [path]: {percentage: percentCompleted, name: path}
                            }))
                        }
                    }))
                }
                const PARALLEL_UPLOAD_COUNT = 3;
                if (_promises.length !== 0) {
                    return throttleActions(_promises, PARALLEL_UPLOAD_COUNT)
                        .then((results) => {
                            let successes = results.filter((result) => result.status !== "rejected");
                            let failures = results.filter((result) => result.status === "rejected");
                            const successUploadProgress = {};
                            successes.forEach((result) => {
                                successUploadProgress[result.path ?? result?.reason?.path] = {
                                    percentage: 100,
                                    name: result.path ?? result?.reason?.path,
                                    status: "complete"
                                }
                            });
                            const failuresUploadProgress = {};
                            failures.forEach((result) => {
                                failuresUploadProgress[result.path ?? result?.reason?.path] = {
                                    percentage: 0,
                                    name: result.path ?? result?.reason?.path,
                                    status: result.status
                                }
                            });
                            if (!isEmptyObject(failuresUploadProgress) || !isEmptyObject(successUploadProgress)) {
                                setFilesUploadProgress(prevState => ({
                                    ...prevState,
                                    ...successUploadProgress,
                                    ...failuresUploadProgress
                                }))
                            }
                        }).finally(() => {
                            setUploading(false);
                            setDoneUploading(analyzeUploadProgress().isDone);
                        });
                }
            }
            uploadFiles().then();
        }
    }, [filesForUpload, filesystem_api, analyzeUploadProgress, uploading]);

    useEffect(() => {
        if (doneUploading || doneDeleting) {
            triggerRefresh().then(() => {
                setDeleting(false);
                setDoneUploading(false);
                setDoneDeleting(false);
            })

            setSelection([]);
        }
    }, [doneUploading, doneDeleting, triggerRefresh])

    function deleteWithConfirm() {
        if (selection.length === 1 && selection[0].type.toLowerCase() === 'regular') {
            setFilesForDelete(_.uniq([...filesForDelete, ...selection]));
        } else {
            setConfirmDeletion(true);
        }
    }

    useEffect(() => {
        if (filesForDelete.length !== 0) {
            setDeleting(true);
            setFilesForDelete([]);
            async function deleteFiles() {
                let _promises = [];
                const base_filesystem_api = getFullPathFromParts();
                for (let i = 0; i < filesForDelete.length; i++) {
                    const { name } = filesForDelete[i];
                    _promises.push(() => axios.delete(`${base_filesystem_api}/${encodeURIComponent(name)}`));
                }
                //Setting this higher than 1 causes a problem when deleting a folder with multiselect that has subfiles. The folder remains after delete though its files are gone
                const PARALLEL_DELETE_COUNT = 1;
                if (_promises.length !== 0) {
                    return throttleActions(_promises, PARALLEL_DELETE_COUNT)
                        .then((results) => {
                            let failures = results.filter((result) => result.status === "rejected");
                            failures.forEach((result) => {
                                if (result.response) {
                                    if (result.response.status === httpStatus.badRequest || result.response.status === httpStatus.conflict) {
                                        toast.notify(({onClose}) => <CustomAlert type='error'
                                                                                 message={result?.response?.data?.message ?? 'Error occurred during delete'}
                                                                                 onClose={onClose}/>,
                                            {duration: null});
                                    }
                                }
                                console.error(result);
                            });
                            if(failures.length === 0){
                                toast.notify(({onClose}) => <CustomAlert type='success'
                                                                         message={"Successfully completed delete"}
                                                                         onClose={onClose}/>);
                            }
                        }).finally(() => {
                            setDoneDeleting(true);
                        });
                }
            }
            deleteFiles().then();
        }
    }, [filesForDelete, getFullPathFromParts]);

    const downloadSingleRow = useCallback((row) => {
        const apiFilePath = `${filesystem_api}/${encodeURIComponent(row.name)}`;
        axios.get(apiFilePath)
            .then((response) => {
                const downloadUrl = response.data.download;
                const iframe = document.createElement('iframe');
                iframe.setAttribute("sandbox", "allow-downloads allow-scripts");
                iframe.src = downloadUrl;
                iframe.setAttribute("style", "display: none");
                document.body.appendChild(iframe);
                //document.body.removeChild(iframe);
            }).catch(error => {
                if (error.response.status === httpStatus.forbidden) {
                    console.error("Download permission denied.");
                } else {
                    console.error('Error while downloading the file:', error);
                }
            }
        );
    }, [filesystem_api]);

    const downloadSelected = useCallback(() => {
        for (let i = 0; i < selection.length; i++) {
            downloadSingleRow(selection[i]);
        }
    }, [downloadSingleRow, selection])

    return (
        <Box p={1} pl={3} pr={3} pt={3} className={'filesystem-content'} sx={{position: 'relative'}}>
            <Prompt when={uploading}
                    message={'Are you sure you want to navigate away from the page? Your upload might be incomplete.'}/>
            <ConfirmDialog
                title={selection.length === 1 && selection[0].type.toLowerCase() === 'directory' ? 'Delete Folder' : 'Delete Selection'}
                open={confirmDeletion}
                setOpen={setConfirmDeletion}
                onConfirm={() => setFilesForDelete(_.uniq([...filesForDelete, ...selection]))}
            >
                This will delete all files and
                folders {selection.length === 1 && selection[0].type.toLowerCase() === 'directory' ? `within "${selection[0].name}"` : "selected, including their contents"}.
                Proceed?
            </ConfirmDialog>
            <FilesystemBreadcrumbs path={path ?? []}/>
            <ActionButtonsComponent
                availableActions={availableActions}
                upload={setFiles}
                download={downloadSelected}
                path={path ?? []}
                delete={deleteWithConfirm}
                open={openSelectedFolder}
                edit={editSelectedFolder}
                isRefreshing={props.loadingFilesystem || props.refreshingFilesystem}
                refresh={props.triggerRefresh}
            />
            <DndProvider backend={HTML5Backend}>
                <FilesystemTableComponent rows={filesystem}
                                          uploadable={availableActions.upload}
                                          triggerRefresh={props.triggerRefresh}
                                          loadingFilesystem={props.loadingFilesystem}
                                          uploading={uploading}
                                          deleting={deleting}
                                          selection={selection}
                                          setSelection={setSelection}
                                          changeFolder={changeFolder}
                                          downloadSelected={downloadSelected}
                                          downloadSingleRow={downloadSingleRow}
                                          uploadFile={setFiles}
                                          numPages={props.numPages}
                                          setNumPages={props.setNumPages}
                                          hasMorePages={props.hasMorePages}
                                          isRefreshing={props.loadingFilesystem || props.refreshingFilesystem}
                                          availableActions={availableActions}
                                          {...props}
                />
            </DndProvider>
            {!isEmptyObject(filesUploadProgress) &&
                <ProgressCard progress={filesUploadProgress} message={uploadingMessage}
                              onClose={() => setFilesUploadProgress({})}/>
            }

        </Box>
    );
}

FilesystemListComponent.propTypes = {
    errorMessage: PropTypes.string,
    errorPath: PropTypes.string
};

FilesystemListComponent.defaultProps = {};

export default FilesystemListComponent;
