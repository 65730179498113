import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isUndefined } from '../../../util/helpers';

export const hasAccess = function checkUserAccess(currentUserRoles, allowedRoles) {

    if (isUndefined(currentUserRoles) || currentUserRoles.length === 0 || allowedRoles.length === 0) {
        return false;
    }

    return currentUserRoles.some(role => allowedRoles.includes(role));
}


function AccessControl(props) {

    const currentUser = useSelector(function getCurrentUserForAccessCheck(state) {
        return state.currentUser
    });
    if (hasAccess(currentUser.roles, props.allowedRoles)) {
        return props.children;
    }

    return null;
}

AccessControl.propTypes = {
    allowedRoles: PropTypes.array.isRequired,
};

AccessControl.defaultProps = {
    allowedRoles: [],
};

export default AccessControl;
