import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Box, Grid } from '@mui/material';
import HelpSystemContext from '../../context/HelpSystemContext';
import useFilesystem from '../../api/useFilesystem';
import PageLoader from '../common/PageLoader';
import { NoOverflowTypography } from '../common/styled';
import FolderFormWrapper from './FolderFormWrapper';
import useQuery from '../../hook/useQuery';
import api_routes from '../../util/api_routes';

function FsFolderUpdateComponent() {

    const [userPermissions, setUserPermissions] = useState();
    let query = useQuery();

    const {data, isLoading} = useFilesystem({params: {path: query.get('path'), metadataOnly: true}});

    const [errors, setErrors] = useState({});
    const [folder, setFolder] = useState();

    useEffect(() => {
        if (!isLoading) {
            setFolder({...data[0]});
        }
    }, [data, isLoading]);

    useEffect(() => setUserPermissions(_.cloneDeep(folder?.userPermissions)), [folder]);

    const updateFolder = async function updateFolder({cloudConnectionId}) {
        let filesystem_api = `${api_routes.filesystem.endpoint}/${query.get('path')}`;
        return await axios.put(filesystem_api,
            {connectionId: cloudConnectionId, permissions: userPermissions },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
    };

    return (<Box p={3}>
        <NoOverflowTypography variant='h5'>{`Edit folder '/${query.get('path')}'`}</NoOverflowTypography>
        <HelpSystemContext.Consumer>
            {open =>
                <Grid container>
                    <Grid container item justifyContent='center'>
                        {!folder && <PageLoader/>}
                    </Grid>
                    <Grid item xs={12} md={open ? 12 : 10} xl={open ? 10 : 8}>
                        {folder &&
                            <FolderFormWrapper userPermissions={userPermissions}
                                               setUserPermissions={setUserPermissions}
                                               folder={folder}
                                               errors={errors}
                                               setErrors={setErrors}
                                               path={query.get('path')}
                                               saveFolder={updateFolder}
                            />
                        }
                    </Grid>
                </Grid>
            }
        </HelpSystemContext.Consumer>
    </Box>);
}

FsFolderUpdateComponent.propTypes = {};

export default FsFolderUpdateComponent;
