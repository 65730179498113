import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Login from '../../component/auth/Login';
import SignUp from '../../component/auth/SignUp';
import PageLoader from '../../component/common/PageLoader';
import UpdatePassword from '../../component/auth/UpdatePassword';
import ServerStartingUp from '../../component/auth/ServerStartingUp';
import { setAuthenticated, setCurrentUser, setSystemInformation } from '../../action';
import { authenticationService } from './authenticationService';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import { isEmpty } from '../../util/helpers';
import jwt_decode from 'jwt-decode';
import useQuery from "../../hook/useQuery";


function Authenticator(props) {

    let query = useQuery();
    const dispatch = useDispatch();
    const currentUser = useSelector(function getCurrentUser(state) {
        return state.currentUser;
    })
    const authenticated = useSelector(function getAuthenticatedStatus(state) {
        return state.auth.authenticated
    });
    const [displayComponent, setDisplayComponent] = useState(<PageLoader/>);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Let the app know that this user is no longer authenticated (this will trigger the login page)
            if (error.response.status === httpStatus.unAuthorized &&
                error.config.url.endsWith(`${api_routes.user.endpoint}/password`) &&
                error.config.method === "patch"){
                console.debug("Skipping logout due to expected error response");
            }
            else if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                return Promise.resolve(authenticationService.logout());
            }
            return Promise.reject(error)
        });

    const dispatchLocalAuthInfo = useCallback(async function dispatchLocalAuthInfo({name, roles = []}) {
        const response = await axios.get(api_routes.ping.endpoint);
        if (roles.length === 0 && !isEmpty(authenticationService.getToken())) {
            roles = jwt_decode(authenticationService.getToken()).authorities;
        }

        dispatch(setSystemInformation(response.data));
        dispatch(setAuthenticated(true));
        dispatch(setCurrentUser({username: name, roles}));
    }, [dispatch]);

    const successfulPasswordUpdate = useCallback(async function successfullyChangedOTP(username, password) {
        const {roles} = await authenticationService.login({username, password});
        await dispatchLocalAuthInfo({name: username, roles});
        setDisplayComponent(props.children);
    }, [dispatchLocalAuthInfo, props.children]);

    const login = useCallback(async function loginToWeb(username, password) {
        const {username: name, resetPassword, roles} = await authenticationService.login({username, password});

        if (resetPassword) {
            setDisplayComponent(<UpdatePassword username={name} currentPassword={password}
                                                onSuccess={successfulPasswordUpdate} />);
        } else {
            await dispatchLocalAuthInfo({name, roles});
            setDisplayComponent(props.children);
        }
    }, [props.children, dispatchLocalAuthInfo, successfulPasswordUpdate]);

    const successfulSignUp = useCallback(function successfullySingedUpTheFirstUser() {
        setDisplayComponent(<Login login={login}/>);
    }, [login]);

    useEffect(() => {
        axios.defaults.headers.common['X-Username'] = `${encodeURIComponent(currentUser.username)}`;
    }, [currentUser]);

    useEffect(() => {
        const authenticate = async function performAuthenticationSteps() {
            // Check if the system hasn't been signed in to before:
            let firstAccess = true;
            let backendRunning = true;
            try {
                let response = await axios.get(api_routes.firstUserConfiguration.endpoint);
                // No user is configured, use first sign in flow
                if (response && response.status === httpStatus.noContent) {
                    setDisplayComponent(<SignUp onSuccess={successfulSignUp}/>);
                }
            } catch (error) {
                if (!isEmpty(error.message) && error.message.includes(httpStatus.notFound.toString())) {
                    firstAccess = false;
                } else if (!isEmpty(error.message) && error.message.includes(httpStatus.unAuthorized.toString())) {
                    return await authenticationService.logout();
                } else {
                    backendRunning = false;
                }
            }

            if (!backendRunning) {
                setDisplayComponent(<ServerStartingUp />);
            }

            if (!firstAccess) {
                if (isEmpty(authenticationService.getToken())) {
                    setDisplayComponent(<Login login={login}/>);
                } else if(query.has('ota')) {
                    const {username: name, roles}  = await authenticationService.loginWithSingleUseToken({token: query.get('ota')});
                    await dispatchLocalAuthInfo({name, roles});
                } else {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${authenticationService.getToken()}`;
                    try {
                        await dispatchLocalAuthInfo({name: authenticationService.getUsername()});
                        setDisplayComponent(props.children);
                    } catch {
                        delete axios.defaults.headers.common['Authorization'];
                        setDisplayComponent(<Login login={login}/>);
                    }
                }
            }
        };

        authenticate().then();
    }, [dispatch, props.children, login, successfulSignUp, dispatchLocalAuthInfo, query]);

    useEffect(() => {
        if (!isEmpty(authenticated) && !authenticated) {
            setDisplayComponent(<Login login={login}/>);
        }
    }, [authenticated, login]);

    return displayComponent;
}

export default Authenticator;
